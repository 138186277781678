import React from 'react';
import { Link } from "react-router-dom";

export default class ButtonSimulator extends React.Component {
    render(){
        return(
            <Link to="/simulator" className="btn_area_btn"><button className="simu_b">
                    料金シミュレーターはこちら
                </button></Link>
        );
    }
}

