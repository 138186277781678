import React from 'react';
import { ReactComponent as Logo } from '../../../images/logo.svg';
import GnavList from '../components/GnavList';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faMoneyCheckAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default class Header extends React.Component {
    render() {
        const { gnavs } = this.props;
        return (
            <header className="header">
                <div className="header_inner">
                    <h1 className="header-logo" ><Link to="/" className="header_logo_link" aria-label="ホーム"><Logo className="logo_svg"/></Link></h1>
                    <GnavList gnavs={gnavs} />
                    <div className="sp_nav">
                        <Link to="/" aria-label="ホーム"><FontAwesomeIcon icon={faHome} /></Link>
                        <Link to="/simulatortop" aria-label="シミュレーター"><FontAwesomeIcon icon={faMoneyCheckAlt} /></Link>
                        <Link to="/contact" aria-label="お問合せ"><FontAwesomeIcon icon={faEnvelope} /></Link>
                    </div>
                </div>
            </header>
        );
    }
}