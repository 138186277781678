import React from 'react';
import * as FadeIn from './FadeinItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHandPointDown } from "@fortawesome/free-regular-svg-icons";
import {checkStyle, handStyle } from "../../Style";
import webHed01 from "../../../images/web_header_01.webp";
import WebInner from "../../../images/web_inner.webp";
import ButtonSimulatorWeb from "./ButtonSimulatorWeb";
import ButtonContact from "./ButtonContact";


export default class WebProdHtmlContent extends React.Component {
    render() {
        return(
            <section className="web_service_section_f">
            <div className="section_left">
              <header className="web_service_header">
                <h2 className="web_s_title">HTML/CSSコーディング</h2>
                <p className="web_s_p">静的HTMLのコーディングやHTMLからWordPressへの実装までお客様のご要望にお応えしてカスタム構築いたします。</p>
              </header>
              <div className="web_service_body">
                <table  border="1" className="web_s_table">
                  <tbody>
                    <tr>
                        <th>項目</th>
                        <th>内容</th>
                        <th>料金単価目安</th>
                    </tr>
                    <tr>
                        <td rowSpan="11">HTML/CSS編集</td>
                        <td>テキスト変更/１ヶ所、50文字まで</td>
                        <td>1,000円~</td>
                    </tr>
                    <tr>
                        <td>カラー変更/1ヶ所</td>
                        <td>2,000円~</td>
                    </tr>
                    <tr>
                        <td>リンク修正/１ヶ所</td>
                        <td>2,500円~</td>
                    </tr>
                    <tr>
                        <td>画像変更/１ヶ所</td>
                        <td>3,000円~</td>
                    </tr>
                    <tr>
                        <td>リンク作成/1ヶ所※APIやツールを使用しないもの</td>
                        <td>5,000円~</td>
                    </tr>
                    <tr>
                        <td>ヘッダー、フッターメニュー追加及び修正/1ヶ所</td>
                        <td>6,000円~</td>
                    </tr>
                    <tr>
                        <td>レイアウト変更/１ヶ所(※画像サイズ変更含む)</td>
                        <td>10,000円~</td>
                    </tr>
                    <tr>
                        <td>類似ページ追加/１ページ(※既存ページレイアウト使用）</td>
                        <td>20,000円~</td>
                    </tr>
                    <tr>
                        <td>新規ページ追加/１ページ(※デザインデータあり)</td>
                        <td>30,000円~</td>
                    </tr>
                    <tr>
                        <td>新規ページ追加/１ページ(※デザインデータなし)</td>
                        <td>60,000円~</td>
                    </tr>
                    <tr>
                        <td>その他</td>
                        <td>別途お見積り</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <footer className="web_service_footer">
                <p>※素材は御社にてご準備をお願いいたします。レイアウト変更が必要な場合は別途、レイアウト変更料金が発生いたします。
                  デザインデータはadobeXD及びPhotoshop,Illustratorファイルでご用意ください。(もしくはpxサイズがわかるもの)※一覧表に記載がないものもご遠慮なくご相談ください！
                  </p>
              </footer>
              <img className="web_img" src={webHed01} alt="#"/>
            </div>
            <FadeIn.DefinL>
            <div className="section_right"> 
                  <div className="section_r_inner">
                    <ul className="inner_upper">
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />柔軟なコーディングに対応</li>
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />お客様に合わせた臨機応変な対応が可能</li>
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />レスポンシブデザインも対応可能</li>
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />１ヶ所のコーディングからご依頼可能</li>
                    </ul>
                    <ul className="inner_lower">
                      <li><FontAwesomeIcon style={handStyle} icon={faHandPointDown} />概算料金をシミュレートする</li>
                          <div className="web_b_area">
                            <ButtonSimulatorWeb />
                          </div>
                      <li><FontAwesomeIcon style={handStyle} icon={faHandPointDown} />コーディングについてのお問い合わせ</li>
                          <div className="web_b_area">
                            <ButtonContact />
                          </div>
                    </ul>
                    <img src={WebInner} alt="#" />
                  </div>
            </div>
            </FadeIn.DefinL>
          </section>
        );
    }
}