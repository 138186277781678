import React from "react";


export default class PrivacyBg extends React.Component {
  render() {
    return (
      <div className="privacy-background">
      </div>
    );
  }
}
