import React from "react";
import serImg01 from "../../../images/service_01.webp";
import serImg02 from "../../../images/service_02.webp";
import serImg03 from "../../../images/service_03.webp";
import serImg01Sp from "../../../images/service_01_sp.webp";
import serImg02Sp from "../../../images/service_02_sp.webp";
import serImg03Sp from "../../../images/service_03_sp.webp";
import { Link } from "react-router-dom";

export default class TopContent extends React.Component {
  render() {
    return (
      <article className="main_content">
        <Link to="/system" className="service_link">
          <section className="service-01">
            <img className="service_image" src={serImg01} alt="#" />
            <img className="service_image_sp" src={serImg01Sp} alt="#" />
            <header>
              <div className="service_title">
                <div className="service_icon"></div>
                <h2>システム開発</h2>
              </div>
            </header>
            <div className="service_content">
              <div className="content_icon"></div>
              <p className="service_item">
                業務基幹システム
                <span className="service_content_dis">
                  (在庫管理・不動産管理・勤怠管理・顧客管理・etc...)
                </span>
              </p>
            </div>
          </section>
        </Link>
        <Link to="/improv" className="service_link">
          <section className="service-02">
            <img className="service_image" src={serImg02} alt="#" />
            <img className="service_image_sp" src={serImg02Sp} alt="#" />
            <header>
              <div className="service_title_r">
                <div className="service_icon"></div>
                <h2>業務改善</h2>
              </div>
            </header>
            <div className="service_content_r">
              <div className="content_icon"></div>
              <p className="service_item">
                業務効率化・業務自動化・テレワーク環境構築
              </p>
            </div>
          </section>
        </Link>
        <Link to="/production" className="service_link">
          <section className="service-03">
            <img className="service_image" src={serImg03} alt="#" />
            <img className="service_image_sp" src={serImg03Sp} alt="#" />
            <header>
              <div className="service_title_s">
                <div className="service_icon"></div>
                <h2>WEB制作</h2>
              </div>
            </header>
            <div className="service_content_s">
              <div className="content_icon"></div>
              <p className="service_item">
                コーポレートサイト・ランディングページ
                <span className="service_content_dis">(etc...)</span>
              </p>
            </div>
          </section>
        </Link>
      </article>
    );
  }
}
