

const popUpStyle = {
    display: 'block',
    opacity: '1',
    visibility: 'visible',
  }
const popCloseStyle = {
    display: 'none',
    opacity: '0',
    visibility: 'hidden',
  }
const popBtnStyle = {
    display: 'block',
    opacity: '1',
    visibility: 'visible',
}
const popBtnClearStyle = {
    display: 'none',
    opacity: '0',
    visibility: 'hidden',
}

//一括出し入れ//
export const YesOnly = () => {
  popUpOpen();
  popUpOk();
  popUpCancelClose();
  popUpClearClose();
}
export const Yesno = () => {
  popUpOpen();
  popUpOkClose();
  popUpCancel();
  popUpClear();
}
//待機用POPUP//
export const WaitWindow = () =>{
  popUpOpen();
  popUpOkClose();
  const popBg = document.querySelector('.popup_background');
  popBg.style.visibility = 'hidden'; 
  const popUp = document.querySelector('.popup');
  popUp.style.backgroundColor = 'rgba(0,0,0,0.3)';
  const closeBtn = document.querySelector('.close_btn').style;
  for(var prop in popBtnClearStyle) {
    closeBtn[prop] = popBtnClearStyle[prop];
  }
}
export const waitWindowClose = () =>{
  const popBg = document.querySelector('.popup_background');
  popBg.style.visibility = 'visible'; 
  const popUp = document.querySelector('.popup');
  popUp.style.backgroundColor = '';
  const closeBtn = document.querySelector('.close_btn').style;
  for(var prop in popBtnStyle) {
    closeBtn[prop] = popBtnStyle[prop];
  }
  popUpClose();
}

//ポップアップの出し入れ//
export const popUpOpen = ()=> {
    const popUp = document.querySelector('.popup').style;
    for(var prop in popUpStyle) {
      popUp[prop] = popUpStyle[prop];
    }

}
export const popUpClose = ()=> {
    const popUp = document.querySelector('.popup').style;
    for(var prop in popCloseStyle) {
      popUp[prop] = popCloseStyle[prop];
    }

}

//ボタンの出し入れ//
export const popUpOk = ()=> {
  const popUpBtn = document.querySelector('.ok_btn').style;
  for(var prop in popBtnStyle) {
    popUpBtn[prop] = popBtnStyle[prop];
  }
}
export const popUpOkClose = ()=> {
  const popUpBtn = document.querySelector('.ok_btn').style;
  for(var prop in popBtnClearStyle) {
    popUpBtn[prop] = popBtnClearStyle[prop];
  }
}

export const popUpClear = ()=> {
  const popUpBtn = document.querySelector('.clear_btn').style;
  for(var prop in popBtnStyle) {
    popUpBtn[prop] = popBtnStyle[prop];
  }
}
export const popUpClearClose = ()=> {
  const popUpBtn = document.querySelector('.clear_btn').style;
  for(var prop in popBtnClearStyle) {
    popUpBtn[prop] = popBtnClearStyle[prop];
  }
}
export const popUpCancel = ()=> {
  const popUpBtn = document.querySelector('.cancel_btn').style;
  for(var prop in popBtnStyle) {
    popUpBtn[prop] = popBtnStyle[prop];
  }
}
export const popUpCancelClose = ()=> {
  const popUpBtn = document.querySelector('.cancel_btn').style;
  for(var prop in popBtnClearStyle) {
    popUpBtn[prop] = popBtnClearStyle[prop];
  }
}
