import React from 'react';
import serImg01 from '../../../images/service_01.webp';
import * as FadeIn from './FadeinItem';
import sevImgBody01 from '../../../images/service_body_01.webp';
import sevImgBody02 from '../../../images/service_body_02.webp';
import sevImgBody03 from '../../../images/service_body_03.webp';
import sevImgBodyP01 from '../../../images/service_body_p01.webp';
import sevImgBodyP02 from '../../../images/service_body_p02.webp';
import sevImgBodyP03 from '../../../images/service_body_p03.webp';
/* import envImg        from '../../../images/environment.webp'; */
import ButtonSimulator from './ButtonSimulator';
import ButtonContact from './ButtonContact';

export default class SystemContent extends React.Component {
    render(){
        return(
            <article className="main_content">
                    <section className="service_header">
                        <img src={serImg01} alt="#" />
                        <header>
                            <div className="service_name">
                                <h1 className="name_h1">システム開発</h1>
                                <div className="name_icon"></div>
                            </div>
                        </header>
                        <div className="service_name_content">
                            <div className="service_name_content_icon">
                                <p className="service_name_p">顧客管理・勤怠管理・不動産管理システム等様々なシステムを要望に合わせてカスタム構築致します。</p>
                            </div>
                        </div>
                    </section>
                    <section className="service_body">
                        <header>
                            <div className="service_body_title">
                                <h2>システム概要</h2>
                            </div>
                        </header>
                        <FadeIn.Up>
                            <div className="service_body_content-01">
                                <div className="service_body_area-01">
                                    <h3 className="service_body_h3">■顧客・売上管理システム</h3>
                                </div>
                                <div className="service_body_area-02">
                                    <p className="service_body_p">顧客・売上管理システムでは、顧客情報や売上履歴などを管理します。 顧客情報や売上情報を管理するだけではなく、
                                     動向・傾向を把握することで御社の戦略をアシストします。 
                                     また、御社独自のシステムを制作することでより解り易く、 
                                     より無駄を省いたシステムを構築できます。</p>
                                    <img className="service_body_imgp" src={sevImgBodyP01} alt="#"/>
                                </div>
                                <img className="service_body_img" src={sevImgBody01} alt="#"/>
                            </div>
                        </FadeIn.Up>
                        <FadeIn.Up>
                            <div className="service_body_content-01">
                                <div className="service_body_area-01">
                                    <h3 className="service_body_h3">■学籍管理システム</h3>
                                </div>
                                <div className="service_body_area-02">
                                    <p className="service_body_p">学籍管理システムでは、各種学校・塾などに対応した生徒情報や出席状況などを管理します。
                                    入出金・登下校ルートや各種書類作成等を一括して管理可能です。 
                                    (成績管理・教材管理・資料管理・入出金処理・変更処理など)。</p>
                                        <img className="service_body_imgp" src={sevImgBodyP02} alt="#"/>
                                </div>
                                <img className="service_body_img" src={sevImgBody02} alt="#"/>
                            </div>
                        </FadeIn.Up>     
                        <FadeIn.Up>
                            <div className="service_body_content-01">
                                <div className="service_body_area-01">
                                    <h3 className="service_body_h3">■不動産管理システム</h3>
                                </div>
                                <div className="service_body_area-02">
                                    <p className="service_body_p">不動産管理システムでは、賃貸マンションやオフィスビルなどの管理を行います。 
                                    入退居や入出金などの基本的な処理から、
                                    各種書類の発行や問い合わせ・対応履歴、駐車場・駐輪場の管理などを包括的に管理できます。
                                    御社の形態に合わせたシステムを製作することで、より効率のよいシステムを構築できます。</p>
                                        <img className="service_body_imgp" src={sevImgBodyP03} alt="#"/>
                                </div>
                                <img className="service_body_img" src={sevImgBody03} alt="#"/>
                            </div>
                        </FadeIn.Up>
                        <p className="system_last_text">ご依頼頂いた内容、ご予算により、最適な言語、データベースをご提案いたします。</p>
{/*                         <FadeIn.Up>
                            <div className="service_body_content-01">
                                <div className="service_body_area-01">
                                    <h3 className="service_body_h3">■開発環境</h3>
                                </div>
                                <div className="service_body_area-02">
                                    <div className="environment_area">
                                        <ul>
                                            <li className="environment_head">開発言語</li>
                                            <li className="environment_li">VB/C#.NET</li>
                                            <li className="environment_li">VBA</li>
                                        </ul>
                                        <ul>
                                            <li className="environment_head">ツール</li>
                                            <li className="environment_li">Visual Studio</li>
                                            <li className="environment_li">Excel,Access</li>
                                        </ul>
                                        <ul>
                                            <li className="environment_head">データベース</li>
                                            <li className="environment_li">SQL server</li>
                                            <li className="environment_li"><span className="sql_none">SQL server/</span>Accdb</li>
                                        </ul>
                                    </div>
                                </div>
                                <img className="service_body_img" src={envImg} alt="#"/>
                            </div>
                        </FadeIn.Up>  */}
                        <div className="button_area">
                            <ButtonSimulator />
                            <ButtonContact />
                        </div>
                    </section>
            </article>
        );
    }
}

