const MenuList = {
  navList: {
          gnavs:  [
            {_id: 'gnav1', content: 'home', link: '/'},
            {_id: 'gnav2', content: 'area',link: '/area'},
            {_id: 'gnav3', content: 'about',link: '/about'},
            {_id: 'gnav4', content: 'contact',link: '/contact'},
          ],
          fItems: [
            {_id: 'fItem1', content: 'simulator', link: '/simulatortop'},
            {_id: 'fItem2', content: 'privacy', link: '/privacy'},
          ]
        }
  };

  export {MenuList};