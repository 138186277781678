import { objWeb } from '../OptionListWeb';
import { YesOnly } from './PopUp';

export function onDetailBtn(e) {

    YesOnly();

    const midKey = e.target.value;
    const midName = e.target.name;
    objWeb[midKey].forEach(function(value){
        if(midName === value.name){
            const midContent = value.content;
            document.querySelectorAll('.detail_message,.detail_message_m,.detail_hr').forEach(el => el.remove());
            const detailMsgm = document.querySelector('.inner_message');
            detailMsgm.insertAdjacentHTML('beforeend',
            `<h3 class="detail_message">【${midName}】</h3>
            <hr class="detail_hr"></hr>
             <p class="detail_message_m">${midContent}</p>`)
        }
    })
}