import { largeLists } from '../OptionListSystem';

/*合計金額算出 */
export function totalSum() {
    const selectItem = document.querySelector('.select_label_l');
    const selectName = selectItem.getAttribute('name');
    largeLists.forEach(function(value){
        if(selectName === value.value ){
            const tPrice = value.price;
            const totalPrice1 = document.querySelector('.total_price')
            const removeElem = document.querySelector('.total_price_label');
            removeElem.remove();
            const t = document.querySelectorAll('.select_label_m');
            let sum = 0;
            for(let i = 0; i < t.length; i++){
                let p = t[i].getAttribute('value');
                let q = parseInt(p);
                sum += q;
            }
            totalPrice1.insertAdjacentHTML('beforeend',
            `<label class="total_price_label">${(tPrice + sum).toLocaleString()}円~(税別)</label>`);
        }
    })
}
