/* import { objWeb } from '../OptionListWeb'; */

export function onNumberChange(e) {
    const number = e.target.value;
    const target = e.target;
    const next = target.nextElementSibling;
    const midPrice = next.getAttribute('name');
    let n = parseInt(number);
    let m = parseInt(midPrice);
    const midTotal = n * m;
    next.innerText = midTotal.toLocaleString() + '円~';
    next.setAttribute('value',midTotal);

    const t = document.querySelectorAll('.middle_sum');
    let sum = 0;
    for(let i = 0; i < t.length; i++){
        let p = t[i].getAttribute('value');
        let q = parseInt(p);
        sum += q;
    }
    const sumPrice = document.querySelector('.total_price_label');
    sumPrice.innerText = sum.toLocaleString() + '円~(税別)'
    if(number > 50){
        alert('数量50を超える場合は別途問い合わせにてご相談下さい。')
        e.target.value = 50;
    }

}