import React from 'react';
import { Link } from "react-router-dom";
import simTopImg from '../../../images/simulator_top.webp';

export default class SimulatorTopContent extends React.Component {
    render(){
        return(
            <article className="main_content">
                <header className="simutop_title_area">
                        <h1 className="simutop_title">料金シミュレーター</h1>
                        <img src={simTopImg} alt="#" />
                </header>
                <div className="simulator_flex">
                    <section className="system_simulator">
                        <header className="system_simulator_header">
                            <h2>業務システム開発</h2>
                        </header>
                        <hr className="sim_hr"></hr>
                        <div className="system_simulator_body">
                            <div>
                                <p>顧客管理・勤怠管理・不動産管理システム等の業務システム開発の概算料金をシミュレートすることができます。</p>
                            </div>
                        </div>
                        <footer>
                            <div className="sim_link">
                                <Link to="/simulator">シミュレーターはこちら</Link>
                            </div>
                        </footer>
                    </section>
                    <section className="web_simulator">
                        <header className="web_simulator_header">
                        <h2>WEB制作</h2>
                        </header>
                        <hr className="sim_hr"></hr>
                        <div className="web_simulator_body">
                            <div>
                                <p>ホームページやランディングページ制作、
                                JavaScriptなどのコーディング概算料金をシミュレートすることができます。</p>
                            </div>
                        </div>
                        <footer>
                            <div className="sim_link">
                                    <Link to="/simulatorweb">シミュレーターはこちら</Link>
                            </div>
                        </footer>
                    </section>
                </div>
            </article>
        );
    }
}

