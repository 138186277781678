export const SEND_TEXT = 'SEND_TEXT';
export const CLEAR_TEXT = 'CLEAR_TEXT'

export const inputText = (text) => ({
  type: SEND_TEXT,
  text,
})

export const clearText = (text) => ({
  type: CLEAR_TEXT,
  text,
})
