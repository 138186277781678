import React from 'react';
import SEO from '../components/seo';
import SimulatorContentWeb from '../components/SimulatorContentWeb';


export default class SimulatorLayoutWeb extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/simulatorweb' 
    });
  }
  render() {
    return (
        <main className="layout_container">
          <SEO title='モアプレイン|WEB制作シミュレーター' description="WEB制作のシミュレーターがご利用になれます。リストに存在しない機能も開発可能です！お問い合わせよりぜひご相談ください。" />
          <SimulatorContentWeb />
        </main>
    );
  }
}

