import React from "react";

export default class AboutContent extends React.Component {
  render() {
    return (
      <article className="about-panel">
        <section className="section-main">
          <div className="main-top">
            <h1 className="about-title01">Company</h1>
            <h1 className="about-title01">info</h1>
            <h1 className="about-title02">会社案内</h1>
            <p className="about-p">
              弊社では事務の簡素化や管理の一元化など、お客様の業務効率の向上を第一に考え、あらゆるニーズに合わせた最適なシステムを実現することができます。
              完全なオーダーメイドですので、既製品では難しい細やかな仕様などにも対応可能です。
            </p>
            <p className="about-p">
              また、メンテナンスにリモートデスクトップを利用することで、地域を選ばない即時対応を行い、業務の停滞を無くすよう努めております。
            </p>
          </div>

          <dl className="CompanyBrochureTable">
            <div className="CompanyBrochureTable-row">
              <dt className="CompanyBrochureTable-key">社名</dt>
              <dd className="CompanyBrochureTable-value">
                有限会社　モアプレイン
              </dd>
            </div>

            <div className="CompanyBrochureTable-row">
              <dt className="CompanyBrochureTable-key">設立</dt>
              <dd className="CompanyBrochureTable-value">1996年7月</dd>
            </div>

            <div className="CompanyBrochureTable-row">
              <dt className="CompanyBrochureTable-key">所在地</dt>
              <dd className="CompanyBrochureTable-value_2">
                本社<br />
                〒819-1616
                <br />
                福岡県糸島市二丈武582-7<br />
                <br />
                開発部<br />
                〒819-0167
                <br />
                福岡県福岡市西区今宿2丁目12-3<br />
                スリーアイランド今宿801
              </dd>
            </div>

            <div className="CompanyBrochureTable-row">
              <dt className="CompanyBrochureTable-key">電話</dt>
              <dd className="CompanyBrochureTable-value">TEL 092-233-4111</dd>
            </div>

            <div className="CompanyBrochureTable-row">
              <dt className="CompanyBrochureTable-key">代表者</dt>
              <dd className="CompanyBrochureTable-value">
                代表取締役　石塚　茂光
              </dd>
            </div>

            <div className="CompanyBrochureTable-row">
              <dt className="CompanyBrochureTable-key">事業内容</dt>
              <dd className="CompanyBrochureTable-value">
                システム開発
                <br />
                業務改善
                <br />
                WEB制作
              </dd>
            </div>
          </dl>
        </section>
    </article>
    );
  }
}
