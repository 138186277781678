import { largeLists,obj } from '../OptionListSystem';
import { errClear } from './ErrClear';
import { onDetailBtn } from './OnDetailBtn';


//全選択ボタン用//
export function onCheckedAll(){
    const checkedAll = document.querySelectorAll('.middle_item');
    checkedAll.forEach(function(elem){
        elem.checked = true;
    })
    onChecked();
}
//追加ボタン用//
export function onChecked(){
    errClear()
    if(document.querySelector('.middle_item')) {
            const list = document.querySelectorAll('.middle_item');
            let flag = false
                    
            list.forEach(function(elem){
                if(elem.checked){
                    const midKey = elem.value;
                    const midName = elem.name;

                    obj[midKey].forEach(function(value){
                        if(midName === value.name ){
                            const midPrice = value.price
                                if(document.querySelector('.select_label_m')) {
                                    const selectLabM = document.querySelectorAll('.select_label_m');
                                    selectLabM.forEach(function(elem){
                                        const readyName = elem.getAttribute('name');
                                        if(midName === readyName){
                                            elem.remove();
                                            flag = true
                                        }
                                    })
                                    const selectAreaM = document.querySelector('.select_area_m');
                                    selectAreaM.insertAdjacentHTML('beforeend',
                                    `<div class="select_label_m" value=${midPrice} name=${midName}><button class="detail_btn" value=${midKey} name=${midName}>説明</button>${midName}
                                    <div class="number_area">
                                    <label class="middle_sum" value=${midPrice} name=${midPrice}>${midPrice.toLocaleString()}円~</label><div></div>` );

                                /*合計金額算出 */
                                const selectItem = document.querySelector('.select_label_l');
                                const selectName = selectItem.getAttribute('name');

                                largeLists.forEach(function(value){
                                    if(selectName === value.value ){
                                        const tPrice = value.price;
                                        const totalPrice1 = document.querySelector('.total_price')
                                        const removeElem = document.querySelector('.total_price_label');
                                        removeElem.remove(elem);

                                        const t = document.querySelectorAll('.select_label_m');
                                        let sum = 0;
                                        for(let i = 0; i < t.length; i++){
                                            let p = t[i].getAttribute('value');
                                            let q = parseInt(p);
                                            sum += q;
                                        }
                                        totalPrice1.insertAdjacentHTML('beforeend',
                                        `<label class="total_price_label">${(tPrice + sum).toLocaleString()}円~(税別)</label>`);
                                    }
                                })
                                //合計金額ここまで//
                                flag = true
                        }else{
                                const selectAreaM = document.querySelector('.select_area_m');
                                selectAreaM.insertAdjacentHTML('beforeend',
                                `<div class="select_label_m" value=${midPrice} name=${midName}><button class="detail_btn" value=${midKey} name=${midName}>説明</button>${midName}
                                <div class="number_area">
                                <label class="middle_sum" value=${midPrice} name=${midPrice}>${midPrice.toLocaleString()}円~</label><div></div>` );

                                /*合計金額算出 */
                                const selectItem = document.querySelector('.select_label_l');
                                const selectName = selectItem.getAttribute('name');

                                largeLists.forEach(function(value){
                                    if(selectName === value.value ){
                                        const tPrice = value.price;
                                        const totalPrice1 = document.querySelector('.total_price')
                                        const removeElem = document.querySelector('.total_price_label');
                                        removeElem.remove(elem);

                                        const t = document.querySelectorAll('.select_label_m');
                                        let sum = 0;
                                        for(let i = 0; i < t.length; i++){
                                            let p = t[i].getAttribute('value');
                                            let q = parseInt(p);
                                            sum += q;
                                        }
                                        totalPrice1.insertAdjacentHTML('beforeend',
                                        `<label class="total_price_label">${(tPrice + sum).toLocaleString()}円~(税別)</label>`);
                                    }
                                })
                                //合計金額ここまで//
                                flag = true
                            }
                        }
                    })
                }else{
                }
            })
            if(flag === false) {
                let errMsg = document.querySelector('.alert_message');
                errMsg.textContent = '機能を選択してください。';
            }
    }else{
        let errMsg = document.querySelector('.alert_message');
        errMsg.textContent = '基幹システムを選択してください。';
    }   
    //ボタンイベント付与//
    const detailBtn = document.querySelectorAll('.detail_btn');
    detailBtn.forEach(el => el.addEventListener('click', onDetailBtn));
};