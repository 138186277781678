import React from 'react';
import caseWebTitleImg from '../../../images/case_web_title.webp';
import * as FadeIn from './FadeinItem';
import caseWebBodyImg from '../../../images/case_web_body.webp';
import areaContentImg01 from '../../../images/weblayout01.webp';
import areaContentImg02 from '../../../images/weblayout02.webp';
import areaContentImg03 from '../../../images/weblayout03.webp';
import areaContentImg04 from '../../../images/weblayout04.webp';
import areaContentImg05 from '../../../images/weblayout05.webp';
import ButtonContact from './ButtonContact';

export default class CaseContentWeb extends React.Component {
    render() {
        return (
            <article className="main_content">
                <section className="service_header">
                    <img src={caseWebTitleImg} alt="#" />
                    <header>
                        <div className="service_name">
                            <h1 className="name_h1">ホームページ制作</h1>
                            <div className="name_icon"></div>
                        </div>
                    </header>
                    <div className="service_name_content">
                        <div className="service_name_content_icon">
                            <p className="service_name_p">弊社指定レイアウトでの制作。HTML/CSSファイルセット及びWordPress移管での納品になります。</p>
                        </div>
                    </div>
                </section>
                <section className="service_body">
                    <header>
                        <div className="service_body_title">
                            <h2>レイアウトの種類</h2>
                        </div>
                    </header>
                    <FadeIn.Up>
                        <div className="service_body_content-01">
                            <div className="service_body_area-01">
                                <h3 className="case_body_h3">カラムデザイン①</h3>
                            </div>
                            <div className="service_body_area-02">
                                <div className="area_content">
                                    <img className="area_content_image" src={areaContentImg01} alt="#" />
                                    <div className="area_content_dis">
                                        <p className="area_content_p">カラムデザイン①は、1.ヘッダー&nbsp;&nbsp;2.メインコンテンツ&nbsp;&nbsp;3.フッターで構成され、コンテンツの順序はスマホで表示した時も変わることなく閲覧が可能です。</p>
                                        <p className="area_content_p_head">メリット</p>
                                        <p className="area_content_p">情報を伝えることが容易でスマホ画面とのデザインと統一感が生まれます。</p>
                                        <p className="area_content_p_head">デメリット</p>
                                        <p className="area_content_p">コンテンツへのリンクを含むサイドメニューがメインの下部に配置されることが多いため、他のコンテンツへのリンクに気づきづらくなります。</p>
                                        {/* <a href="samplehtml/sample01/index.html" target="blank"><button className="sample_b">サンプルはこちら</button></a> */}
                                    </div>
                                </div>
                            </div>
                            <img className="service_body_img" src={caseWebBodyImg} alt="#" />
                        </div>
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <div className="service_body_content-01">
                            <div className="service_body_area-01">
                                <h3 className="case_body_h3">カラムデザイン②</h3>
                            </div>
                            <div className="service_body_area-02">
                                <div className="area_content">
                                    <img className="area_content_image" src={areaContentImg02} alt="#" />
                                    <div className="area_content_dis">
                                        <p className="area_content_p">カラムデザイン②は、1.ヘッダー&nbsp;&nbsp;2.サイドメニュー&nbsp;&nbsp;3.メインコンテンツ&nbsp;&nbsp;4.フッターで構成され、サイドメニューにコンテンツを提示し、ユーザーが目的とするコンテンツを探しやすくカテゴリー分けしています。</p>
                                        <p className="area_content_p_head">メリット</p>
                                        <p className="area_content_p">メインコンテンツに加えて他の情報も掲載することができるので回遊率の向上が期待でき、情報をサイドメニューに掲載することでクリック率を高めることもできます。</p>
                                        <p className="area_content_p_head">デメリット</p>
                                        <p className="area_content_p">スマホ画面でカラムデザイン②を表示すると文字が小さく視認性が悪くなるため離脱率が高まります。※弊社ではスマホ画面はカラムデザイン①に変更しています。</p>
                                        {/* <button className="sample_b">サンプルはこちら</button> */}
                                    </div>
                                </div>
                            </div>
                            <img className="service_body_img" src={caseWebBodyImg} alt="#" />
                        </div>
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <div className="service_body_content-01">
                            <div className="service_body_area-01">
                                <h3 className="case_body_h3">カラムデザイン③</h3>
                            </div>
                            <div className="service_body_area-02">
                                <div className="area_content">
                                    <img className="area_content_image" src={areaContentImg03} alt="#" />
                                    <div className="area_content_dis">
                                        <p className="area_content_p">カラムデザイン③は、1.ヘッダー&nbsp;&nbsp;2.サイドメニュー&nbsp;&nbsp;3.メインコンテンツ&nbsp;&nbsp;4.サイドコンテンツ&nbsp;&nbsp;5.フッターで構成され、カラムデザイン②と同じようにユーザーが目的とするコンテンツを探しやすくカテゴリー分けしています。</p>
                                        <p className="area_content_p_head">メリット</p>
                                        <p className="area_content_p">カラムデザイン③は一度に多くの情報を表示させることができ、ユーザーのスクロール量を減らす事ができます。</p>
                                        <p className="area_content_p_head">デメリット</p>
                                        <p className="area_content_p">情報過多になり、メインコンテンツ以外に注意が向けられ目的のコンテンツを探しづらくなります。</p>
                                        {/* <button className="sample_b">サンプルはこちら</button> */}
                                    </div>
                                </div>
                            </div>
                            <img className="service_body_img" src={caseWebBodyImg} alt="#" />
                        </div>
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <div className="service_body_content-01">
                            <div className="service_body_area-01">
                                <h3 className="case_body_h3">グリッドデザイン</h3>
                            </div>
                            <div className="service_body_area-02">
                                <div className="area_content">
                                    <img className="area_content_image" src={areaContentImg04} alt="#" />
                                    <div className="area_content_dis">
                                        <p className="area_content_p">グリッドデザインは、1.ヘッダー&nbsp;&nbsp;2.コンテンツ&nbsp;&nbsp;3.フッターで構成され、多数のコンテンツを表示することができます。</p>
                                        <p className="area_content_p_head">メリット</p>
                                        <p className="area_content_p">ユーザーに対して沢山の情報を伝えることができ、整列された見た目になります。</p>
                                        <p className="area_content_p_head">デメリット</p>
                                        <p className="area_content_p">情報量が多いため、ユーザーがコンテンツの閲覧に迷う可能性があります。</p>
                                        {/* <button className="sample_b">サンプルはこちら</button> */}
                                    </div>
                                </div>
                            </div>
                            <img className="service_body_img" src={caseWebBodyImg} alt="#" />
                        </div>
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <div className="service_body_content-01">
                            <div className="service_body_area-01">
                                <h3 className="case_body_h3">フルスクリーンデザイン</h3>
                            </div>
                            <div className="service_body_area-02">
                                <div className="area_content">
                                    <img className="area_content_image" src={areaContentImg05} alt="#" />
                                    <div className="area_content_dis">
                                        <p className="area_content_p">フルスクリーンデザインは画面全体に画像や動画を表示させ、デザイン重視型のサイトになります。</p>
                                        <p className="area_content_p_head">メリット</p>
                                        <p className="area_content_p">画面全体で情報を伝えることができ、ユーザーにインパクトを与えることができます。</p>
                                        <p className="area_content_p_head">デメリット</p>
                                        <p className="area_content_p">中身が見えにくいこともあり、ユーザーが離れてしまう可能性があります。</p>
                                        {/* <button className="sample_b">サンプルはこちら</button> */}
                                    </div>
                                </div>
                            </div>
                            <img className="service_body_img" src={caseWebBodyImg} alt="#" />
                        </div>
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <div className="service_body_content-01">
                            <div className="service_body_area-01">
                                <h3 className="service_body_h3">■料金表</h3>
                            </div>
                            <div className="service_body_area-02">
                                <div className="environment_area">
                                    <ul>
                                        <li className="environment_head">1カラムデザイン</li>
                                        <li className="environment_li">TOPページ(スライダー含む)+5ページ+コンタクトフォーム</li>
                                        <li className="environment_li">250,000円～</li>
                                    </ul>
                                    <ul>
                                        <li className="environment_head">2カラムデザイン</li>
                                        <li className="environment_li">TOPページ(サイドメニュー含む)+5ページ+コンタクトフォーム</li>
                                        <li className="environment_li">280,000円～</li>
                                    </ul>
                                    <ul>
                                        <li className="environment_head">3カラムデザイン</li>
                                        <li className="environment_li">TOPページ(サイドメニュー×2含む)+5ページ+コンタクトフォーム</li>
                                        <li className="environment_li">300,000円～</li>
                                    </ul>
                                    <ul>
                                        <li className="environment_head">グリッドデザイン</li>
                                        <li className="environment_li">TOPページ(グリッド数12)+5ページ+コンタクトフォーム</li>
                                        <li className="environment_li">350,000円～</li>
                                    </ul>
                                    <ul>
                                        <li className="environment_head">フルスクリーンデザイン</li>
                                        <li className="environment_li">TOPページ(動画設置及びスライダー含む)+5ページ+コンタクトフォーム</li>
                                        <li className="environment_li">350,000円～</li>
                                    </ul>
                                </div>
                            </div>
                            <p className="case_message">個別でのカスタムも可能です！お気軽にお問い合わせください。</p>
                            <p className="case_message_2">※素材はお客様にてご準備をお願いいたします。</p>
                            <img className="service_body_img" src={caseWebBodyImg} alt="#" />
                        </div>
                        <div className="button_area">
                            <ButtonContact />
                        </div>
                    </FadeIn.Up>
                </section>
            </article>
        );
    }
}

