import React from 'react';
import AboutContent from '../components/AboutContent';
import SEO from '../components/seo';


export default class AboutLayout extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/about' 
    });
  }
  render() {
    return (
      <>
        <main className="layout_container">
          <SEO title='モアプレイン|会社案内' description="弊社では事務の簡素化や管理の一元化など、お客様の業務効率の向上を第一に考え、あらゆるニーズに合わせた最適なシステムを実現することができます。 完全なオーダーメイドですので、既製品では難しい細やかな仕様などにも対応可能です。
            また、メンテナンスにリモートデスクトップを利用することで、地域を選ばない即時対応を行い、業務の停滞を無くすよう努めております。" />
          <AboutContent />
        </main>
        </>
    );
  }
}