import React from "react";
import ContactForm from "../components/ContactForm";
import SEO from '../components/seo';

export default class ContactFormLayout extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/contact' 
    });
  }
  render() {
    return (
      <main className="layout_container">
        <SEO title='モアプレイン|お問い合わせ' description="まずはどんな事でもお気軽にお問い合わせください。" />
        <ContactForm />
      </main>
    );
  }
}
