import React from 'react';
import AreaContent from '../components/AreaContent';
import SEO from '../components/seo';


export default class AreaLayout extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/area' 
    });
  }
  
  render() {
    return (
      <>
        <main className="layout_container">
          <SEO title='モアプレイン|対応エリア' description="スカイプやZOOMなどを使用してのヒアリングにて、システム構築の打ち合わせを行い、
          リモートデスクトップを使用しオンラインでの納品が可能ですので、日本全国どこでもご対応可能です。一度ご連絡を！" />
          <AreaContent />
        </main>
        </>
    );
  }
}