import React from "react";
import * as FadeIn from './FadeinItem';
import * as ScrollIn from './ScrollinItem';
import serImg03 from "../../../images/service_03.webp";
import webImg04 from "../../../images/web_04.webp";
import WebProdHtmlContent from "./WebProdHtmlContent";
import WebProdJsContent from "./WebProdJsContent";
import WebProdDesignContent from "./WebProdDesignContent";
import WebProdThemeContent from "./WebProdThemeContent";
import { Link } from "react-router-dom";




export default class WebProdContent extends React.Component {
  render() {
    return (
      <article className="web_content">
        <FadeIn.Defin>
            <header className="web_content_header">
              <div className="web_header_l">
              <div className="content_icon"></div>
              </div>
              <div className="web_header_r">
                  <h1 className="web_title">WEB制作</h1>
                  <p className="web_dis">コーポレートサイトやランディングページなど様々なWebページを作成いたします。</p>
                  <p className="web_dis_2"><Link to="/caseweb">＜ホームページを1から作成したい方はこちら＞</Link></p>
                  <img src={serImg03} alt="#"/>
              </div>
            </header>
          </FadeIn.Defin>
        <div className="web_service_scroll">
              <WebProdHtmlContent />
          <ScrollIn.ScrollF>
              <WebProdJsContent />
          </ScrollIn.ScrollF>
          <ScrollIn.ScrollS>
              <WebProdDesignContent />
          </ScrollIn.ScrollS>
          <ScrollIn.ScrollT>
              <WebProdThemeContent />
          </ScrollIn.ScrollT>
          <FadeIn.Defin>
            <section className="web_service_e">
              <header className="web_end_header">
                <h2>ご依頼の流れ</h2>
              </header>
              <div className="web_end_body">
                <h3>STEP1:　お問い合わせフォームよりお問い合わせください。</h3>
                  <p>まずはどんな事もでもお気軽にお問い合わせください。</p>
                  <hr></hr>
                <h3>STEP2:　ヒアリング</h3>
                <p>ご連絡させていだだきご依頼内容をヒアリングさせていただきます。料金シミュレーターからお問い合わせいただくとスムーズです。</p>
                <hr></hr>
                <h3>STEP3:　最適なプランのご提案とお見積りの提出</h3>
                <p>ご依頼内容をおまとめさせていただき、お見積もりを提出させて頂きます。お見積もり提出は後日、もしくは内容により当日に提出させていただいております。</p>
                <hr></hr>
                <h3>STEP4:　ご契約・ご発注</h3>
                <p>お見積書の金額に同意頂けましたら、正式発注となりご契約となります。</p>
                <hr></hr>
                <h3>STEP5:　制作スタート</h3>
                <p>要所要所にてお打合せを設けながら制作します。柔軟なスタッフが対応いたしますので、ご安心ください。</p>
                <hr></hr>
                <h3>STEP6:　納品</h3>
                <p>全てのページが完成しましたら御社にてご確認をいただきます。ご確認完了後、納品となります。</p>
                <hr></hr>
              </div>
              <footer className="web_end_footer">
              </footer>
              <img className="web_img" src={webImg04} alt="#"/>
            </section>
          </FadeIn.Defin>
        </div>
      </article>
    );
  }
}
