import React from 'react';
import { Link } from 'react-router-dom';

export default class FooterItem extends React.Component {
    render() {
        const { fItem } = this.props;
        return (
            <li className="footer-item">
                <Link to={fItem.link} className="footer-link" target={fItem.target}>{fItem.content}</Link>
            </li>
        );
    }
}