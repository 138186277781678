import React from 'react';
import SEO from '../components/seo';
import SimulatorContent from '../components/SimulatorContent';


export default class SimulatorLayout extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/simulator' 
    });
  }
  render() {
    return (
        <main className="layout_container">
          <SEO title='モアプレイン|業務システム開発シミュレーター' description="業務システム開発のシミュレーターがご利用になれます。リストに存在しない機能も開発可能です！お問い合わせよりぜひご相談ください。" />
          <SimulatorContent />
        </main>
    );
  }
}

