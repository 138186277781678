import { largeLists,obj } from '../OptionListSystem';
import { errClear } from './ErrClear';
import { totalSum } from './TotalSum';

//選択済みアイテムクリア//
export function removelabelItem(){
    errClear();
    document.querySelectorAll('.select_label_m').forEach(el => el.remove());
};

//チェックボックスの全選択解除//
export function onCheckedRemoveAll(){
    if(document.querySelector('.total_price_label')){
        if(document.querySelector('.select_label_m')){
            const checkedRemoveAll = document.querySelectorAll('.middle_item');
            checkedRemoveAll.forEach(function(elem){
                elem.checked = false;
            })
            removelabelItem();
            totalSum();
            document.querySelectorAll('.detail_midname, .detail_message_m').forEach(el =>el.remove());
        }else{
            let errMsg = document.querySelector('.alert_message');
            errMsg.textContent = '機能が追加されていません。';
        }
    }else{
        let errMsg = document.querySelector('.alert_message');
        errMsg.textContent = '基幹システムを選択してください。';
    }
}

//削除ボタン用//
export function onRemove() {
    errClear()
    if(document.querySelector('.middle_item')) {
            const list = document.querySelectorAll('.middle_item');

            let flgNo = 0

            list.forEach(function(elem){
                if(elem.checked){
                    const midKey = elem.value;
                    const midName = elem.name;
                    obj[midKey].forEach(function(value){
                        if(midName === value.name ){
                                if(document.querySelector('.select_label_m')) {
                                    const selectLabM = document.querySelectorAll('.select_label_m');
                                    selectLabM.forEach(function(elem){
                                        const readyName = elem.getAttribute('name');
                                        if(midName === readyName){
                                            elem.remove();
                                            let errMsg = document.querySelector('.alert_message');
                                            errMsg.textContent = (`選択した機能を削除しました。`);

                                            flgNo = 1                                               

                                            /*合計金額算出 */
                                            const selectItem = document.querySelector('.select_label_l');
                                            const selectName = selectItem.getAttribute('name');

                                            largeLists.forEach(function(value){
                                                if(selectName === value.value ){
                                                    const tPrice = value.price;
                                                    const totalPrice1 = document.querySelector('.total_price')
                                                    const removeElem = document.querySelector('.total_price_label');
                                                    removeElem.remove(elem);

                                                    const t = document.querySelectorAll('.select_label_m');
                                                    let sum = 0;
                                                    for(let i = 0; i < t.length; i++){
                                                        let p = t[i].getAttribute('value');
                                                        let q = parseInt(p);
                                                        sum += q;
                                                    }
                                                    totalPrice1.insertAdjacentHTML('beforeend',
                                                    `<label class="total_price_label">${tPrice + sum}円~</label>`);
                                                }
                                            })
                                            //合計金額ここまで//
                                            
                                        }else{
                                            flgNo = 3
                                        }
                                    })
                        }else{
                            flgNo = 2
                            }
                        }
                    })
                }else{
                }
            })
            let errMsg = document.querySelector('.alert_message');
            switch(flgNo){
                case 0:
                    errMsg.textContent = '削除する機能を選択してください。';
                    break;
                case 2:
                    errMsg.textContent = '機能が追加されていません。';
                    break;
                case 3:
                    errMsg.textContent = '選択した項目は追加されていません。';
                    break;
                default:
                    break;
            }
    }else{
        let errMsg = document.querySelector('.alert_message');
        errMsg.textContent = '基幹システムを選択してください。';
    }   
};