import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import topImg1 from '../../../images/top_01.webp';
import topImg2 from '../../../images/top_02.webp';
import topImg3 from '../../../images/top_03.webp';
import topImg4 from '../../../images/top_04.webp';


export default function TopSlider() {
  const settings = {
    autoplay: true, 
    dots: false, 
    arrows: false,
    infinite: true, 
    autoplaySpeed: 4000,
    speed: 1500, 
    fade: true, 
  };
  return (
    <div className="top-slider">
        <Slider {...settings}>
            <div>
                <h3>
                  <img src={topImg1} alt="top-01"/>
                　<p className="top_message-1">毎日のルーティン作業に膨大な時間を要していませんか？</p>
                </h3>
            </div>
            <div>
                <h3>
                  <img src={topImg2} alt="top-02"/>
                  <p className="top_message-2">システムが古くなり作業効率が悪くなっていませんか？</p>
                </h3>
            </div>
            <div>
                <h3>
                  <img src={topImg3} alt="top-03"/>
                  <p className="top_message-3">モアプレインが業務の簡素化をアシストいたします。</p>
                </h3>
            </div>
            <div>
                <h3>
                  <img src={topImg4} alt="top-04"/>
                  <p className="top_message-4">オーダーメイドでお客様のニーズに合わせた、</p>
                  <p className="top_message-5">きめ細やかなソフト創りを目指しています。</p>
                </h3>
            </div>
        </Slider>
    </div>
  );
}


