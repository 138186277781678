import React from "react"
import styled from "styled-components"
import InViewMonitor from "react-inview-monitor"

export const ScrollF = ({ children, delay, margin = "0%" }) => (
  <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
    <ScrollInScrollF isActive={false} delay={delay}>{children}</ScrollInScrollF>
  </InViewMonitor>
)

export const ScrollS = ({ children, delay, margin = "0%" }) => (
  <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
    <ScrollInScrollS isActive={false} delay={delay}>{children}</ScrollInScrollS>
  </InViewMonitor>
)

export const ScrollT = ({ children, delay, margin = "0%" }) => (
  <InViewMonitor childPropsInView={{ isActive: true }} intoViewMargin={margin}>
    <ScrollInScrollT isActive={false} delay={delay}>{children}</ScrollInScrollT>
  </InViewMonitor>
)

const ScrollIn = styled.div`
  opacity: 1;
  transition: opacity 1s ease, transform 2s ease;
  ${({ isActive }) => isActive && `opacity: 1;`}
  ${({ delay }) => delay && `transition-delay: ${delay}ms;`}
`
const ScrollInScrollF = styled(ScrollIn)`
  transform: translateY(500px);
  ${({ isActive }) => isActive && `transform: translateY(20px);`}
`
const ScrollInScrollS = styled(ScrollIn)`
  transform: translateY(500px);
  ${({ isActive }) => isActive && `transform: translateY(40px);`}
`
const ScrollInScrollT = styled(ScrollIn)`
  transform: translateY(500px);
  ${({ isActive }) => isActive && `transform: translateY(60px);`}
`
