import React from 'react';
import FooterList from './FooterList';
import GnavList from './GnavList';

export default class Footer extends React.Component {
    render() {
        const { fItems } = this.props;
        const { gnavs} = this.props;
        return (
            <footer className="footer">
                <div className="footer_inner">
                <div className="footer_l">
                        <div className="footer_l_sp">
                            <GnavList gnavs={gnavs} />
                        </div>
                            <FooterList fItems={fItems} />
                    </div>
                    <div className="footer_r">
                        <div className="footer_copy">
                            <p>© MorePlain Co.,Ltd. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}