import React from "react";

export default class PrivacyContent extends React.Component {
  render() {
    return (
      <article className="privacy-panel">
        <section className="section-main">
          <div className="main-top">
            <h1 className="privacy-title">プライバシーポリシー</h1>
          </div>
          <div className="main">
            <h2>個人情報保護法</h2>
            <p className="privacy-p">
              弊社は健全なコミュニケーシヨンを通じて最適なサービスを提供する上で、個人情報の保護が経営の最重要課題と考えております。
            </p>
            <p className="privacy-p">
              このため、個人情報保護に向けた取組み方針を定め、個人情報保護に関する基本方針としてご理解をいただくために、ここにご案内いたします。
            </p>
          </div>
          <div className="main">
            <h2>1.個人情報とは</h2>
            <p className="privacy-p">
              特定の個人を識別できる情報を個人情報といいます。また、他の情報とあわせることで特定の個人が識別できる情報も個人情報といたします。
            </p>
            <p className="privacy-p">
              ご提供いただく情報や直接ご記入いただいたアンケートおよび各種書類に記載された情報もこれにあたります。
            </p>
          </div>
          <div className="main">
            <h2>2.個人情報の収集について</h2>
            <p className="privacy-p">
              個人情報をご記入いただく際には、その場において改めて、利用目的を明確にいたします。
            </p>
            <p className="privacy-p">
              ご提供いただいた個人情報は、明示した利用目的の範囲内で利用いたします。
            </p>
            <p className="privacy-p">
              また、個人情報を同意なく利用目的以外に利用することはありません。
            </p>
          </div>
          <div className="main">
            <h2>3.個人情報の利用について</h2>
            <p className="privacy-p">
              サービス向上のために、下記の目的で個人情報を利用いたします。
            </p>
            <p className="privacy-p">
              ①
              弊社からのご案内などを提供するため、郵便、電話、電子メールなどの方法によりお知らせすること。
            </p>
            <p className="privacy-p">
              ②
              訓練実施効果やサービス改善あるいはニーズの把握などのために各種アンケート調査を実施すること。
            </p>
          </div>
          <div className="main">
            <h2>4.個人情報の第三者への提供について</h2>
            <p className="privacy-p">
              次の場合を除き、事前同意をいただかない限り個人情報を第三者に提供することはありません。
            </p>
            <p className="privacy-p">
              ① 人命や人権、身体または財産を保護するために緊急を要する場合。
            </p>
            <p className="privacy-p">
              ②
              司法機関、警察等の公共機関による法令に基づく要請に協力する必要がある場合。
            </p>
            <p className="privacy-p">③ その他法令に基づく場合。</p>
          </div>
          <div className="main">
            <h2>5.個人情報保護の体制について</h2>
            <p className="privacy-p">
              個人情報を大切な情報と認識し、その安全を図るために適切な管理を実施し、
            </p>
            <p className="privacy-p">
              不正アクセス、個人情報の紛失、破壊、改ざんおよび漏えい等の防止ならびに是正を行います。
            </p>
          </div>
          <div className="main">
            <h2>6.法令の遵守と改善について</h2>
            <p className="privacy-p">
              個人情報保護のための関連法令およびその他の規則を誠実に遵守いたします。
            </p>
            <p className="privacy-p">
              このため、個人情報保護方針および個人情報保護に関するルールを常に見直して、個人情報の取り扱いおよび管理を継続的に改善してまいります。
            </p>
          </div>
        </section>
    </article>
    );
  }
}
