const checkStyle = {
    padding: 0,
    fontSize: 20,
    color: "green",
    margin: "0 20px 0 10px",
}

const handStyle = {
    padding: 0,
    fontSize: 30,
    color: "rgb(1, 64, 201)",
    margin: "0 20px -5px 10px",
}


export  {checkStyle, handStyle } ;