import React from 'react';
import TopSlider from '../components/TopSlider';
import TopNews from '../components/TopNews';
import TopContent from '../components/TopContent';
import SEO from '../components/seo';



export default class TopLayout extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/' 
    });
  }
  render() {
    return (
        <main className="layout_container">
          <SEO title='モアプレイン|事務の簡素化をアシストする。' description="モアプレインでは事務の簡素化や管理の一元化など、
          完全なオーダーメイドで顧客のニーズに合わせた、きめ細やかなソフト創りを目指しています。" />
          <TopSlider />
          <TopNews />
          <TopContent />
        </main>
    );
  }
}

