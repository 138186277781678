import { largeLists } from '../OptionListSystem';

//基幹システム詳細表示//
export function onDetail(e) {
    const arrayName = e.target.value;
    largeLists.forEach(function (value) {
        const largeValue = value.value
        if( arrayName === largeValue) {
            const largeContent = value.content ;
            const msgClass = document.querySelector('.detail_message');
            if(msgClass){
               msgClass.remove();
            }
            const detailMsg = document.querySelector('.detail_message_area_l');
            detailMsg.insertAdjacentHTML('beforeend',
            `<p class="detail_message_l">${largeContent}</p>`)
        }
    })
}