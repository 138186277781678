import React from 'react';

export default class TopNews extends React.Component {
    render(){
        return(
            <section className="news">
                <header>
                    <h2 className="news_info">～News～</h2>
                </header>
                <div className="info">
                    <dl><dt>2021年4月</dt><dd><a href="https://hanihari.jp/" target="blank">株式会社はにはり</a>との共同プロジェクト「マイクロマクロス」は<a href="https://micromacross.com/" target="blank">こちら</a></dd></dl>
                    <dl><dt>2021年4月</dt><dd>システム開発及びWEB制作の料金シミュレーターが御利用になれます。</dd></dl>
                    <dl><dt>2021年4月</dt><dd><a href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/0000164708_00001.html" target="blank">新型コロナウイルス感染症について(厚生労働省)</a></dd></dl>
                    <dl><dt>2021年3月</dt><dd>ホームページをリニューアルしました。</dd></dl>
                </div>
            </section>
        );
    }
}

