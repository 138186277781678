import React from 'react';
import PrivacyContent from '../components/PrivacyContent';
import SEO from '../components/seo';


export default class PrivacyLayout extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/privacy' 
    });
  }
  render() {
    return (
        <main className="layout_container">
          <SEO title='モアプレイン|プライバシーポリシー' description="お問い合わせの際には「個人情報の取扱いについて」お読みいただき、予め同意の上、お問い合わせください。" />
          <PrivacyContent />
        </main>
    );
  }
}