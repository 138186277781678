import React from 'react';

export const largeLists = [
    { id: 1, value: "array1", name: "顧客・売上管理システム", price: 200000, content: "顧客・売上管理システムでは、顧客情報や売上履歴などを管理します。 顧客情報や売上情報を管理するだけではなく、 動向・傾向を把握することで御社の戦略をアシストします。 また、御社独自のシステムを制作することでより解り易く、 より無駄を省いたシステムを構築できます。"},
    { id: 2, value: "array2", name: "学籍管理システム", price: 300000, content: "学籍管理システムでは、各種学校・塾などに対応した生徒情報や出席状況などを管理します。入出金・登下校ルートや各種書類作成等を一括して管理可能です。 (成績管理・教材管理・資料管理・入出金処理・変更処理など)。"},
    { id: 3, value: "array3", name: "不動産管理システム", price: 250000,content: "不動産管理システムでは、賃貸マンションやオフィスビルなどの管理を行います。 入退居や入出金などの基本的な処理から、各種書類の発行や問い合わせ・対応履歴、駐車場・駐輪場の管理などを包括的に管理できます。御社の形態に合わせたシステムを製作することで、より効率のよいシステムを構築できます。"},
    { id: 4, value: "array4", name: "共通システム", price: 0,content: "単体開発はもちろん、各システムへオプションとしてもご利用になれます。"}
];
export const obj = {
    array1 : [
    { value: "array1", name: '顧客情報', price: 100000, content: "顧客管理の基となる顧客情報を登録・変更します。"},
    { value: "array1", name: '取引先情報', price: 60000, content: "仕入れ先や下請け業者などの関連企業の情報を管理します。"},
    { value: "array1", name: '接触・対応履歴', price: 50000, content: "営業にて接触した新規顧客の情報や反応、また既存の顧客からの問い合わせや対応の履歴を管理します。"},
    { value: "array1", name: '個人別購入履歴', price: 30000, content: "各顧客の購入商品・購入日などを遡って確認します。"},
    { value: "array1", name: '条件別顧客抽出', price: 30000, content: "地域や年齢・性別などの各条件別での顧客の一覧を出力します。"},
    { value: "array1", name: '販売管理', price: 150000, content: "フォームからの入力やEXCELデータからの取込などで売上を登録します。"},
    { value: "array1", name: '仕入れ・納入・入金処理', price: 70000, content: "仕入れ先への発注書の出力や入金を処理します。"},
    { value: "array1", name: '請求・入金処理', price: 100000, content: "顧客への納品書や請求書の作成や顧客からの入金を処理します。"},
    { value: "array1", name: '年次、月次書類作成', price: 30000, content: "月次売上一覧や年次決算売上などの各種書類を出力します。"},
    { value: "array1", name: '条件別売上抽出', price: 50000, content: "地域や時期・商品カテゴリなどの各条件を絞って売り上げを抽出し分析します。"}
    ],
    array2 : [
    { value: "array2", name: '生徒情報', price: 80000, content: "学籍管理の基となる生徒の情報を登録・変更します。"},
    { value: "array2", name: '生徒別成績管理', price: 30000, content: "各生徒の成績を遡って確認します。"},
    { value: "array2", name: '条件別成績分析', price: 50000, content: "クラスや学期・担当教師などの各条件での成績を抽出し分析します。"},
    { value: "array2", name: '成績証明書発行', price: 30000, content: "各生徒に提出する成績の証明書を出力します。"},
    { value: "array2", name: '仕入れ・納入・入金管理', price: 100000, content: "教材の仕入れ・納入から取引先への入金を処理します。"},
    { value: "array2", name: '自動引落データ作成・取込', price: 80000, content: "生徒の学費データを元に各銀行に対応した形式にて自動引落データを作成し、引落結果データを取り込みます。"},
    { value: "array2", name: '条件別入金一覧抽出', price: 50000, content: "年月や学科などの条件別の入金・未納の一覧を出力します。"},
    { value: "array2", name: '留年・休学・転科・退学処理', price: 50000, content: "留年や休学などの特殊な処理を行います。"},
    { value: "array2", name: '過入金に対する充当・返金処理', price: 50000, content: "過入金に対して次月授業料へ充当や返金などの処理を行います。"},
    { value: "array2", name: '資料請求管理', price: 50000, content: "入学検討者からの資料請求の情報や送付履歴などを管理します。"},
    { value: "array2", name: '入学願書管理', price: 30000, content: "入学願書に対する一連の処理を行います。"},
    { value: "array2", name: '合格通知・学割定期券・学生証..etc', price: 30000, content: "合格通知書や学生証などの各種印刷物を出力します。(※フォーマット毎に料金が発生します。)"},
    ],
    array3 : [
    {value: "array3", name: '入居者管理', price: 150000, content: "賃貸管理の基となる入居者の情報を登録・変更します。"},
    {value: "array3", name: '部屋・設備管理', price: 50000, content: "部屋や備えているエアコンなどの設備の情報を管理します。"},
    {value: "array3", name: '請求処理', price: 100000, content: "家賃や共益費・駐車場などを入居者情報を元に一括作成します。"},
    {value: "array3", name: '入金処理(自動引落データ作成・取込)', price: 80000, content: "請求データを基に各銀行や郵便局対応した形式にて自動引落データを作成し、引落結果データを取り込みます。"},
    {value: "array3", name: '延滞処理', price: 50000, content: "家賃などの入金・滞納状況を確認し、督促状出力等の処理を行います。"},
    {value: "array3", name: '送金処理', price: 50000, content: "施設オーナーへの送金を処理します。"},
    {value: "array3", name: '問い合わせ対応履歴', price: 80000, content: "入居者からの問い合わせや苦情などの履歴を内容カテゴリや日時などで抽出し、一覧表示します。"},
    {value: "array3", name: 'ガス・水道料金管理', price: 50000, content: "ガス・水道のメーターを管理し、請求額の算出を行います。"},
    {value: "array3", name: '駐車・駐輪場管理', price: 80000, content: "駐車場・駐輪場の契約状況や請求状況などの管理を行います。"}
    ],
    array4 : [
    {value: "array4", name: 'RPAによる作業の自動化', price: 100000, content: "RPAを使用し、各種作業の自動化を行います。"},
    {value: "array4", name: 'SM送信機能', price: 70000, content: "指定した条件・内容にてスマートフォンへショートメールを一斉送信する機能を追加します。"},
    {value: "array4", name: '自動引落による入金処理', price: 80000, content: "登録された口座よりシステム内のデータを基に自動引落データを作成し、銀行や郵便局とのやり取りを行います。"},
    {value: "array4", name: 'EXCELデータの取込', price: 50000, content: "現在EXCELにて管理されているデータとシステム内のデータとを連動させる仕組みを構築します。"},
    {value: "array4", name: 'VPN導入による遠隔ネットワーク構築', price: 50000, content: "VPNを使用し、遠隔地とのネットワークを構築し共通のデータでのシステム運用を実現します。"},
    {value: "array4", name: 'データベース移行', price: 100000, content: "現在利用中のシステムのデータを新規導入するシステムのデータへ変換・移行します。"},
    ]
    }

 export const largeOptions = largeLists.map(
    (n) =>(
        <option key={n.id} value={n.value}>{n.name}</option>
    )
)



