import React from 'react';

export const largeListsWeb = [
    { id: 1 , value: "array1", name: "HTML/CSSコーディング", price: 0, content: "静的HTMLのコーディングやHTMLからWordPressへの実装までお客様のご要望にお応えしてカスタム構築いたします。"},
    { id: 2 , value: "array2", name: "JavaScript構築", price: 0, content: "スライドショーやプルダウンメニューなど様々な効果をJavaScriptにて実装いたします。"},
    { id: 3 , value: "array3", name: "デザイン制作", price: 0 ,content: "ホームページやランディングページ等様々なデザインを提供いたします。"},
    { id: 4 , value: "array4", name: "WordPress構築(Webサイト移行及び構築)", price: 0 ,content: "HTMLからWordPressへの移行をお客様のご要望にお応えしてカスタム構築いたします。"}
];
export const objWeb = {
    array1 : [
    { value: "array1", name: 'テキスト変更', price: 1000, content: "お好きな箇所のHTMLテキストを修正・変更いたします。テキスト変更/1ヶ所、50文字まで"},
    { value: "array1", name: 'カラー変更', price: 2000, content: "お好きな箇所のカラーをCSS修正・変更いたします。カラー変更/1ヶ所"},
    { value: "array1", name: 'リンク修正', price: 2500, content: "リンク切れや修正及び変更に対応いたします。リンク修正/1ヶ所"},
    { value: "array1", name: '画像変更', price: 3000, content: "商品紹介ページの写真や人物画像などご要望にお応えして変更いたします。画像変更/1ヶ所"},
    { value: "array1", name: 'リンク作成', price: 5000, content: "ご要望にお応えして新しくリンクを作成いたします。"},
    { value: "array1", name: 'メニュー追加', price: 6000, content: "ヘッダー、フッターメニュー追加及び修正を対応いたします。"},
    { value: "array1", name: 'レイアウト変更', price: 10000, content: "既存のレイアウトを変更対応いたします。"},
    { value: "array1", name: '類似ページ追加', price: 20000, content: "既存のページレイアウトを使用し新規ページを構築いたします。"},
    { value: "array1", name: '新規ページ追加(デザインあり)', price: 30000, content: "新規ページ追加/1ページ(※デザインデータあり)"},
    { value: "array1", name: '新規ページ追加(デザインなし)', price: 60000, content: "新規ページ追加/1ページ(※デザインデータなし)"}
    ],
    array2 : [
    { value: "array2", name: 'モーダルウィンドウ', price: 2500, content: "モーダルウィンドウとは、元のウィンドウの上に別枠で表示されるウィンドウのことです。"},
    { value: "array2", name: 'スムーススクロール', price: 3000, content: "スムーススクロールとはページ内コンテンツにスクロールで移動する機能のことです。"},
    { value: "array2", name: 'ロールオーバー', price: 3000, content: "ロールオーバーとは主にリンク要素にマウスポインタを載せた際に、色や画像等が切り替わる機能のことです。"},
    { value: "array2", name: '画像スライドショー', price: 10000, content: "一定間隔で画像が自動的に変わるシンプルなスライドショーやランダムに画像が流れたり色んな機能を実装する事ができます。"},
    { value: "array2", name: 'プルダウンメニュー', price: 10000, content: "特定の位置をマウスでクリックすると、その機能に関連したメニューが表示される機能のことです。"},
    { value: "array2", name: 'ローディング画面', price: 10000, content: "ページの読み込み中にアニメーションや画像を表示させる機能のことです。"},
    { value: "array2", name: '動画設置及び制御', price: 15000, content: "ご自身の動画やYouTube動画などを設置する事ができます。"}
    ],
    array3 : [
    {value: "array3", name: '一般類似ページ', price: 5000, content: "お客様のご要望を詳しくヒアリングさせて頂いた上で、写真・イラスト・文章等、ご用意頂きたい素材がある場合は、お客様にご協力をお願いしております。"},
    {value: "array3", name: 'メニューデザイン', price: 5000, content: "お客様のご要望を詳しくヒアリングさせて頂いた上で、写真・イラスト・文章等、ご用意頂きたい素材がある場合は、お客様にご協力をお願いしております。"},
    {value: "array3", name: '案内ページ', price: 7000, content: "お客様のご要望を詳しくヒアリングさせて頂いた上で、写真・イラスト・文章等、ご用意頂きたい素材がある場合は、お客様にご協力をお願いしております。"},
    {value: "array3", name: '最新情報ページ', price: 8000, content: "お客様のご要望を詳しくヒアリングさせて頂いた上で、写真・イラスト・文章等、ご用意頂きたい素材がある場合は、お客様にご協力をお願いしております。"},
    {value: "array3", name: 'ブログページ', price: 10000, content: "お客様のご要望を詳しくヒアリングさせて頂いた上で、写真・イラスト・文章等、ご用意頂きたい素材がある場合は、お客様にご協力をお願いしております。"},
    {value: "array3", name: 'ローディング画面', price: 10000, content: "お客様のご要望を詳しくヒアリングさせて頂いた上で、写真・イラスト・文章等、ご用意頂きたい素材がある場合は、お客様にご協力をお願いしております。"},
    {value: "array3", name: '一覧ページ(グリッドデザイン)', price: 15000, content: "お客様のご要望を詳しくヒアリングさせて頂いた上で、写真・イラスト・文章等、ご用意頂きたい素材がある場合は、お客様にご協力をお願いしております。"},
    {value: "array3", name: '一覧ページ(カードデザイン)', price: 15000, content: "お客様のご要望を詳しくヒアリングさせて頂いた上で、写真・イラスト・文章等、ご用意頂きたい素材がある場合は、お客様にご協力をお願いしております。"},
    {value: "array3", name: 'トップページ', price: 30000, content: "お客様のご要望を詳しくヒアリングさせて頂いた上で、写真・イラスト・文章等、ご用意頂きたい素材がある場合は、お客様にご協力をお願いしております。"}
        ],
    array4 : [
    {value: "array4", name: '類似ページ追加', price: 5000, content: "既存のHTMLページをWordPress用に構築いたします。"},
    {value: "array4", name: 'ヘッダー・フッター構築', price: 7000, content: "既存のヘッダー・フッターをWordPress用に構築いたします。"},
    {value: "array4", name: 'トップページ', price: 15000, content: "既存のトップページをWordPress用に構築いたします。"},
    {value: "array4", name: 'オリジナルブログページ', price: 30000, content: "ブログの閲覧ページをご希望のレイアウトで構築いたします。"},
    {value: "array4", name: 'オリジナルアーカイブページ', price: 30000, content: "オリジナルアーカイブページをご希望のレイアウトで構築いたします。"},
    {value: "array4", name: 'コンタクトフォームの設置', price: 30000, content: "ご希望のレイアウトでコンタクトフォームを設置いたします。"},
    {value: "array4", name: 'WordPress及びプラグインのインストール', price: 30000, content: "WordPress及び最低限のプラグインをインストール代行いたします。"},
    {value: "array4", name: 'プログラムファイルのアップロード及び調整', price: 30000, content: "作成したプログラムをサーバーへアップロード代行いたします。"}
        ]
    }

 export const largeOptions = largeListsWeb.map(
    (n) =>(
        <option key={n.id} value={n.value}>{n.name}</option>
    )
)



