import {SEND_TEXT} from '../actions/index'
import {CLEAR_TEXT} from '../actions/index'

const initialAppState = {
  text: '',
};

const textreducer = (state = initialAppState, action) => {
  if (action.type === SEND_TEXT) {
    return Object.assign({}, state, {
      text: action.text,
    });
  }else if(action.type === CLEAR_TEXT){
    return {
      initialAppState
    };
  }else{
    return state;
  };
};

export default textreducer;