import React from 'react';
import * as FadeIn from './FadeinItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHandPointDown } from "@fortawesome/free-regular-svg-icons";
import {checkStyle, handStyle } from "../../Style";
import webImg02 from "../../../images/web_02.webp";
import WebInner from "../../../images/web_inner.webp";
import ButtonSimulatorWeb from "./ButtonSimulatorWeb";
import ButtonContact from "./ButtonContact";

export default class WebProdDesignContent extends React.Component {
    render(){
        return(
            <section className="web_service_section_l">
              <div className="section_left">
                <header className="web_service_header">
                  <h2 className="web_s_title">デザイン制作</h2>
                  <p className="web_s_p">ホームページやランディングページ等様々なデザインを提供いたします。</p>
                </header>
                <div className="web_service_body">
                <table  border="1" className="web_s_table">
                  <tbody>
                    <tr>
                        <th>項目</th>
                        <th>内容</th>
                        <th>料金単価目安</th>
                    </tr>
                    <tr>
                        <td rowSpan="9">レイアウト構成</td>
                        <td>一般類似ページ</td>
                        <td>5,000円~</td>
                    </tr>
                    <tr>
                        <td>メニューデザイン</td>
                        <td>5,000円~</td>
                    </tr>
                    <tr>
                        <td>案内ページ</td>
                        <td>7,000円~</td>
                    </tr>
                    <tr>
                        <td>最新情報ページ</td>
                        <td>8,000円~</td>
                    </tr>
                    <tr>
                        <td>ブログページ</td>
                        <td>10,000円~</td>
                    </tr>
                    <tr>
                        <td>一覧ページ(グリッドデザイン)</td>
                        <td>1,5000円~</td>
                    </tr>
                    <tr>
                        <td>一覧ページ(カードデザイン)</td>
                        <td>15,000円~</td>
                    </tr>
                    <tr>
                        <td>トップページ</td>
                        <td>30,000円~</td>
                    </tr>
                    <tr>
                        <td>その他</td>
                        <td>別途お見積り</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <footer className="web_service_footer">
                <p>※ホームページやランディンページ制作時のワイヤーフレーム作成料金になります。デザインデータをお持ちの場合は料金は発生しません。※一覧表に記載がないものもご遠慮なくご相談ください！</p>
              </footer>
                <img className="web_img" src={webImg02} alt="#"/>
              </div>
              <FadeIn.DefinL>
                <div className="section_right"> 
                      <div className="section_r_inner">
                      <ul className="inner_upper">
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />目的に合わせた配色選び。</li>
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />業種に合わせたレイアウト構成</li>
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />Webページ以外のデザインも対応可能</li>
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />その他ご要望にお応えします。</li>
                    </ul>
                    <ul className="inner_lower">
                      <li><FontAwesomeIcon style={handStyle} icon={faHandPointDown} />概算料金をシミュレートする</li>
                          <div className="web_b_area">
                            <ButtonSimulatorWeb />
                          </div>
                      <li><FontAwesomeIcon style={handStyle} icon={faHandPointDown} />デザイン制作についてのお問い合わせ</li>
                          <div className="web_b_area">
                            <ButtonContact />
                          </div>
                    </ul>
                        <img src={WebInner} alt="#" />
                      </div>
                </div>
             </FadeIn.DefinL>
            </section>
        );
    }
}