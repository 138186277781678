import React from "react";
import SEO from "../components/seo";
import WebProdContent from "../components/WebProdContent";

export default class WebProdLayout extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/production' 
    });
  }
  render() {
    return (
      <main className="layout_container">
        <SEO title='モアプレイン|WEB制作' description="コーポレートサイトやランディングページなど様々なWebページを作成いたします。" />
        <WebProdContent />
      </main>
    );
  }
}
