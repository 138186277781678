import React from 'react';
import conImg from '../../../images/contact_title.webp';
import * as Form from '../components/ContactFormItem';
import TextareaAutosize from 'react-textarea-autosize';
import {connect} from 'react-redux';
import *as actions from '../../../actions/index';
import { Link } from "react-router-dom";
import { YesOnly, Yesno, WaitWindow, waitWindowClose } from '../../Functions/PopUp';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAllClearForm = this.handleAllClearForm.bind(this);
        this.state = {
            requiredError: false,
            flag: true,
        }; 
    }
    
    handleAllClearForm() {
        Yesno();
        document.querySelectorAll('.detail_message,.detail_message_m,.detail_hr').forEach(el => el.remove());
        const detailMsgm = document.querySelector('.inner_message');
        detailMsgm.insertAdjacentHTML('beforeend',
        `<h3 class="detail_message">フォームの内容をクリアしますか？</h3>`)
    }

    handleSubmit(e) {
        e.preventDefault();
        const detailMsgm = document.querySelector('.inner_message');
        const fetchForm = document.querySelector('.my_form');
        if(Form.nameVal === 0 && Form.emailVal === 0 && Form.messageVal === 0 
            && fetchForm.name.value !== '' && fetchForm.email.value !== '' && fetchForm.message.value !== ''){
            WaitWindow();
            document.querySelectorAll('.detail_message,.detail_message_m,.detail_hr').forEach(el => el.remove());
            detailMsgm.insertAdjacentHTML('beforeend',
            `<h3 class="detail_message">お問い合わせ内容を送信中です。</h3>`)
            const url = 'https://moreplain.co.jp/mail.php';
            let formData = new FormData(fetchForm);
            fetch(url, {
                method: 'POST',
                body: formData,
                mode: 'cors',
            }).then((response) => {
                if(!response.ok) {
                    waitWindowClose();
                    YesOnly();
                    document.querySelectorAll('.detail_message,.detail_message_m,.detail_hr').forEach(el => el.remove());
                    detailMsgm.insertAdjacentHTML('beforeend',
                    `<h3 class="detail_message">送信に失敗しました。お手数ですが再度送信ボタンを押してください。</h3>`)
                    } 
                    waitWindowClose();
                    this.props.onClick(this.state);
                    this.setState({
                    requiredError: true,
                    });
                    window.scrollTo(0,0);
                 }).catch(err => {
                    waitWindowClose();
                    YesOnly();
                    document.querySelectorAll('.detail_message,.detail_message_m,.detail_hr').forEach(el => el.remove());
                    detailMsgm.insertAdjacentHTML('beforeend',
                    `<h3 class="detail_message">送信に失敗しました。お手数ですが再度送信ボタンを押してください。${err}</h3>`)
                 });
        }else{
            YesOnly();
            document.querySelectorAll('.detail_message,.detail_message_m,.detail_hr').forEach(el => el.remove());
            detailMsgm.insertAdjacentHTML('beforeend',
            `<h3 class="detail_message">必須項目に不備があります。再入力もしくは修正をお願いいたします。</h3>`)
        };
    }
    
    render() {
        let contactForm;
        let main,mid,price;
        if(this.state.requiredError){
            contactForm = (
                <div className="done_form">
                    <p className="done_form_inner_bold">お問い合わせ内容を送信しました。</p>
                    <p className="done_form_p">このたびはお問い合わせいただきありがとうございます。</p>
                    <p className="done_form_p">ご入力いただいたメールアドレス宛に確認メールをお送りしましたのでご確認ください。</p>
                    <p className="done_form_p">確認メールが届いていない場合には、メールアドレスが誤っているか、
                        確認メールが迷惑メールフォルダ等に振り分けられている可能性がありますので、再度ご確認をお願いいたします。</p>
                    <p className="done_form_p">なお、お問い合わせ内容につきましては、通常3営業日程度を目処に、弊社担当者よりご回答させていただきます。
                        いましばらくお待ちくださいませ。</p>
                    <p className="done_form_p">万一、ご回答メールが届かない場合は、送信トラブル等の可能性もありますので、大変お手数ではございますが、
                        もう一度フォームよりお問い合わせいただくか、お電話にてお問い合わせをお願いいたします。</p>
                    <p className="done_form_p">今後ともご愛顧賜りますようよろしくお願い申し上げます。</p>
                    <div className="back_btn">
                        <Link to="/"><button>ホームへ戻る</button></Link>
                    </div>
                </div>
            )
        }else{
            if(this.props.text){
                main = this.props.text.main
                mid = this.props.text.mid
                price = this.props.text.price
                contactForm = (
                    <form className="my_form" key={this.state.flag} onSubmit={this.handleSubmit} >
                         <p className="my_form_p">※下記フォームに必要事項を入力後、送信ボタンを押してください。</p>
                        <Form.InputName />
                        <div className="conpany_input">
                            <p className="form_label">会社名・事業所名</p>
                            <input className="company_text" type="text" name="company"  />
                        </div>
                        <div className="tel_input">
                            <p className="form_label">電話番号</p>
                            <input className="tel_text" type="text" name="tel"  />
                        </div>
                        <Form.InputEmail />
                        <Form.MessageContent />
                        <div className="simu_input">
                            <div className="form_label simu_inner_flex">
                                <p>シミュレーター内容※編集不可</p>
                            </div>
                            <div className="simu_text" >
                                <input className="simu_inner" type="text" name="main" value={main} readOnly tabIndex="-1"/>
                                <TextareaAutosize className="simu_inner" type="text" name="middle" value={mid} readOnly tabIndex="-1"/>
                                <input className="simu_inner" type="text" name="price" value={price} readOnly tabIndex="-1"/>
                            </div>
                        </div>
                        <div className="privacy_check">
                            <p className="privacy_label">プライバシーポリシー</p>
                            <p>お問い合わせの際には<Link to="/privacy">「個人情報の取扱いについて」</Link>お読みいただき、予め同意の上、お問い合わせください。</p>
                            <input type="checkbox" required="required"/>個人情報保護方針に同意する
                        </div>
                        <p className="pre_text">※シミュレーターによる開発費はあくまでも目安です。実際のデータ量や組込み機能によって変動します。</p>
                        <div className="submit_btn_area">
                            <input className="submit_btn_area_btn ok_btn" type="submit" value="送信" />
                            <button className="submit_btn_area_btn reset_btn" type="button" onClick={this.handleAllClearForm}>リセット</button>
                        </div>
                    </form>
                        )
            }else{
                main = ''
                mid = ''
                price =''
                contactForm = (
                    <form className="my_form" key={this.state.flag} onSubmit={this.handleSubmit} >
                         <p className="my_form_p">※下記フォームに必要事項を入力後、送信ボタンを押してください。</p>
                        <Form.InputName />
                        <div className="conpany_input">
                            <p className="form_label">会社名・事業所名</p>
                            <input className="company_text" type="text" name="company"  />
                        </div>
                        <div className="tel_input">
                            <p className="form_label">電話番号</p>
                            <input className="tel_text" type="text" name="tel"  />
                        </div>
                        <Form.InputEmail />
                        <Form.MessageContent />
                        <div className="privacy_check">
                            <p className="privacy_label">プライバシーポリシー</p>
                            <p>お問い合わせの際には<Link to="/privacy">「個人情報の取扱いについて」</Link>お読みいただき、予め同意の上、お問い合わせください。</p>
                            <input type="checkbox" required="required"/>個人情報保護方針に同意する
                        </div>
                        <div className="submit_btn_area">
                            <input className="submit_btn_area_btn ok_btn" type="submit" value="送信" />
                            <button className="submit_btn_area_btn reset_btn" type="button" onClick={this.handleAllClearForm}>リセット</button>
                        </div>
                    </form>
                        )
            }
        };
        return (
            <article className="contact_content">
                <header className="contact_title_area">
                    <h1 className="contact_title">お問い合わせ</h1>
                    <img src={conImg} alt="#" />
                </header>
                <div className="contact_body">
                <section className="form_inner">
                    {contactForm}
                </section>
                </div>
            </article>
        );
    }
}
const mapStateToProps = (state) => ({
    text: state.text.text,
});
function mapDispatchToProps(dispatch) {
    return {
    onClick(text){
        dispatch(actions.clearText(text));
    }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);