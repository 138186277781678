import React from "react";
import webbgImg from "../../../images/web_bg.webp";

export default class WebProvBg extends React.Component {
  render() {
    return (
      <div className="web_bg">
        <div className="web_bg_img">
          <img src={webbgImg} alt="#" />
        </div>
        <div className="cube-01">
          <div className="face top"></div>
          <div className="face left"></div>
          <div className="face right"></div>
        </div>
        <div className="cube-02">
          <div className="face top"></div>
          <div className="face left"></div>
          <div className="face right"></div>
        </div>
        <div className="cube-03">
          <div className="face top"></div>
          <div className="face left"></div>
          <div className="face right"></div>
        </div>
        <div className="cube-04">
          <div className="face top"></div>
          <div className="face left"></div>
          <div className="face right"></div>
        </div>
        <div className="cube-05">
          <div className="face top"></div>
          <div className="face left"></div>
          <div className="face right"></div>
        </div>
        <div className="cube-06">
          <div className="face top"></div>
          <div className="face left"></div>
          <div className="face right"></div>
        </div>
        <div className="cube-07">
          <div className="face top"></div>
          <div className="face left"></div>
          <div className="face right"></div>
        </div>
      </div>
    );
  }
}
