import React from 'react';
import * as FadeIn from './FadeinItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHandPointDown } from "@fortawesome/free-regular-svg-icons";
import {checkStyle, handStyle } from "../../Style";
import webImg03 from "../../../images/web_03.webp";
import WebInner from "../../../images/web_inner.webp";
import ButtonSimulatorWeb from "./ButtonSimulatorWeb";
import ButtonContact from "./ButtonContact";


export default class WebProdThemeContent extends React.Component {
    render(){
        return(
            <section className="web_service_section_r">
            <div className="section_right-2">
                <header className="web_service_header">
                  <h2 className="web_s_title">WordPress構築<span className="web_s_span">(Webサイト移行及び構築)</span></h2>
                  <p className="web_s_p">HTMLからWordPressへの以降をお客様のご要望にお応えしてカスタム構築いたします。</p>
                </header>
                <div className="web_service_body">
              <table  border="1" className="web_s_table">
                  <tbody>
                    <tr>
                        <th>項目</th>
                        <th>内容</th>
                        <th>料金単価目安</th>
                    </tr>
                    <tr>
                        <td rowSpan="9">PHP構築</td>
                        <td>類似ページ/1ページ</td>
                        <td>5,000円~</td>
                    </tr>
                    <tr>
                        <td>ヘッダー・フッター構築</td>
                        <td>7,000円~</td>
                    </tr>
                    <tr>
                        <td>トップページ</td>
                        <td>15,000円~</td>
                    </tr>
                    <tr>
                        <td>オリジナルブログページ</td>
                        <td>30,000円~</td>
                    </tr>
                    <tr>
                        <td>オリジナルアーカイブページ</td>
                        <td>30,000円~</td>
                    </tr>
                    <tr>
                        <td>コンタクトフォームの設置</td>
                        <td>30,000円~</td>
                    </tr>
                    <tr>
                        <td>WordPress及びプラグインのインストール</td>
                        <td>30,000円~</td>
                    </tr>
                    <tr>
                        <td>プログラムファイルのアップロード及び調整</td>
                        <td>30,000円~</td>
                    </tr>
                    <tr>
                        <td>その他</td>
                        <td>別途お見積り</td>
                    </tr>
                </tbody>
              </table>
            </div>
            <footer className="web_service_footer">
              <p>※素材は御社にてご準備をお願いいたします。レイアウト変更が必要な場合は別途、レイアウト変更料金が発生いたします。
                 HTML/CSS/JSファイルにてご用意ください。※一覧表に記載がないものもご遠慮なくご相談ください！</p>
            </footer>
                <img className="web_img" src={webImg03} alt="#"/>
              </div>
              <FadeIn.DefinL>
              <div className="section_left-2"> 
                    <div className="section_l_inner">
                    <ul className="inner_upper">
                        <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />ご自身でも編集可能に！</li>
                        <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />記事の追加でSEO対策！</li>
                        <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />お問い合わせ対応もスムーズに！</li>
                        <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />ゼロからの制作も承っております。</li>
                  </ul>
                  <ul className="inner_lower">
                    <li><FontAwesomeIcon style={handStyle} icon={faHandPointDown} />概算料金をシミュレートする</li>
                        <div className="web_b_area">
                          <ButtonSimulatorWeb />
                        </div>
                    <li><FontAwesomeIcon style={handStyle} icon={faHandPointDown} />WordPress移行についてのお問い合わせ</li>
                        <div className="web_b_area">
                          <ButtonContact />
                        </div>
                  </ul>
                      <img src={WebInner} alt="#" />
                    </div>
              </div>
              </FadeIn.DefinL>
          </section>
        );
    }
}