import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TopLayout from './TopLayout';
import SystemLayout from './SystemLayout';
import ImprovLayout from './ImprovLayout';
import WebProdLayout from '../layouts/WebProdLayout';
import WebProvBg from '../components/WebProdBg';
import AboutBg from '../components/AboutBg';
import AreaBg from '../components/AreaBg';
import PrivacyBg from '../components/PrivacyBg';
import SimulatorLayout from '../layouts/SimulatorLayout';
import SimulatorLayoutWeb from '../layouts/SimulatorLayoutWeb';
import { Route } from "react-router-dom";
import ScrollToTop from '../components/ScrollToTop';
import { spring, AnimatedSwitch } from 'react-router-transition';
import { MenuList } from '../../MenuList';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretSquareUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AboutLayout from './AboutLayout';
import AreaLayout from './AreaLayout';
import PrivacyLayout from './PrivacyLayout';
import ContactFormLayout from './ContactFromLayout';
import SimulatorTopLayout from './SimulatorTopLayout';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { popUpClose } from '../../Functions/PopUp';
import {connect} from 'react-redux';
import *as actions from '../../../actions/index';
import CaseWebLayout from './CaseWebLayout';





 
class AppLayout extends React.Component {
    constructor(props) {
      super(props);
      this.handleAllClearForm = this.handleAllClearForm.bind(this);
  }
  handleAllClearForm() {
    document.querySelector('.name_text').value = '';
    document.querySelector('.company_text').value = '';
    document.querySelector('.tel_text').value = '';
    document.querySelector('.mail_text').value = '';
    document.querySelector('.message_text').value = '';
    document.querySelectorAll('.simu_inner').forEach(el => el.remove());
    this.props.onClick(this.state);
    popUpClose();
  }
  render() {
    function bounce(val) {
      return spring(val, {
        stiffness: 130,
        damping: 22,
      });
    }
    function scrTp(){
      window.scrollTo(0, 0);
    };
    function mapStyles(styles) {
      return {
        opacity: styles.opacity,
        transform: `scale(${styles.scale})`,
      };
    }
    return (
      <>
          <div className="container">
            <Header gnavs={MenuList.navList.gnavs} />
            <div className="popup">
              <div className="popup_inner">
                  <div className="close_btn" onClick={popUpClose}>
                      <FontAwesomeIcon icon={faTimes}  />
                  </div>
                  <div className="inner_message"></div>
                      <button className="ok_btn" onClick={popUpClose}>OK</button>
                  <div className="popup_btn_area">
                      <button className="clear_btn" onClick={this.handleAllClearForm}>クリア</button>
                      <button className="cancel_btn" onClick={popUpClose}>キャンセル</button>
                  </div>
              </div>
            <div className="popup_background" onClick={popUpClose}></div>
            </div>
              {/*bgRoute*/}
              <Route path="/production" component={WebProvBg} />
              <Route path="/about" component={AboutBg} />
              <Route path="/area" component={AreaBg} />
              <Route path="/privacy" component={PrivacyBg} />
                <ScrollToTop>
                  <AnimatedSwitch
                  atEnter={{ opacity: 0, scale: 0 }}
                  atLeave={{ opacity: bounce(0), scale: bounce(7) }}
                  atActive={{ opacity: bounce(1), scale: bounce(1) }}
                  mapStyles={mapStyles}
                  className="switch-wrapper"
                  >
                    {/*headerRoute*/}
                    <Route path="/" exact component={TopLayout} />
                    <Route path="/area" component={AreaLayout}  />
                    <Route path="/about" component={AboutLayout}  />
                    <Route path="/contact" component={ContactFormLayout}  />
                    {/*bodyRoute*/}
                    <Route path="/system" component={SystemLayout}  />
                    <Route path="/improv" component={ImprovLayout}  />
                    <Route path="/production" component={WebProdLayout}  />
                    <Route path="/caseweb" component={CaseWebLayout}  />
                    {/*btnRoute*/}
                    <Route path="/simulator" component={SimulatorLayout}  />
                    <Route path="/simulatorweb" component={SimulatorLayoutWeb}  />
                    {/*footerRoute*/}
{/*                     <Route path='/micromacross' component={() => { 
                        window.location.href = 'https://micromacross.com/'; 
                        return null;
                    }}/> */}
                    <Route path="/simulatortop" component={SimulatorTopLayout}  />
                    <Route path="/privacy" component={PrivacyLayout}  />
                  </AnimatedSwitch>
                </ScrollToTop>
          </div>
          <div className="footer_container">
            <div className="page_scroll">
              <div className="top_b">
                <Link to="/system"><button className="system_b">システム開発</button></Link>
                <Link to="/improv"><button className="improv_b">業務改善</button></Link>
                <Link to="/production"><button className="web_b">WEB制作</button></Link>
              </div>
              <FontAwesomeIcon icon={faCaretSquareUp} onClick={scrTp} /></div>
            <Footer fItems={MenuList.navList.fItems} gnavs={MenuList.navList.gnavs} />
          </div>

      </>
    );
  }
}
const mapStateToProps = (state) => ({
  text: state.text.text,
});
function mapDispatchToProps(dispatch) {
  return {
  onClick(text){
      dispatch(actions.clearText(text));
  }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
