import React from 'react';

export let nameVal,nameText,emailVal,emailText,messageVal,messageText ;

//required//
export class InputName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            hasNameError: "",
        };
        nameVal = this.state.hasNameError
        nameText =this.state.name
    }

    handleNameChange(e) {
        const inputValue = e.target.value;
        let isEmpty;
        
        if(inputValue === "") {
            isEmpty = 1
        }else if(inputValue.length > 50 ){
            isEmpty = 2
        }else{
            isEmpty = 0
        };
        this.setState({
            name: inputValue,
            hasNameError: isEmpty,
        },
            () => {                        
                nameVal = this.state.hasNameError
                nameText =this.state.name
            }
        );
    };
      
    render() {
        let nameErrorText;
        if(this.state.hasNameError === 1) {
            nameErrorText = (
                <p className="error_text">お名前を入力してください。</p>
            );
        }else if(this.state.hasNameError === 2){
            nameErrorText = (
                <p className="error_text">お名前は50文字以内で入力してください。</p>
            );
        };
        return (
            <>
            <div className="name_input">
                <p className="form_label">お名前<span className="required_item">(必須)</span></p>
                <input className="name_text" type="text" name="name" value={this.state.name} onChange={(e) =>this.handleNameChange(e)} />
                
            </div>
            {nameErrorText} 
            </>
        );
    }
}
export class InputEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            hasEmailError: "",
        };
        emailVal = this.state.hasEmailError
        emailText = this.state.email
    }

    handleEmailChange(e) {
        const inputValue = e.target.value;
        let isEmpty = inputValue === "";
        var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
        if(inputValue === "") {
            isEmpty = 1
        }else if(reg.test(inputValue)){
            isEmpty = 0
        }else{
            isEmpty = 2
        };
        this.setState({
            email: inputValue,
            hasEmailError: isEmpty,
        },
            () => {                        
                emailVal = this.state.hasEmailError
                emailText = this.state.email
            }
        );
    };
    
    render() {
        let emailErrorText;
        if(this.state.hasEmailError === 1) {
            emailErrorText = (
                <p className="error_text">メールアドレスを入力してください</p>
            );
        }else if(this.state.hasEmailError === 2) {
            emailErrorText = (
                <p className="error_text" style={{color:"gray"}}>正しいメールアドレスを入力してください</p>
            );
        };
    
        return (
            <>
            <div className="email_input">
                <p className="form_label">メールアドレス<span className="required_item">(必須)</span></p>
                <input className="mail_text" type="text" name="email" value={this.state.name} onChange={(e) =>this.handleEmailChange(e)} />
            </div>  
            {emailErrorText}   
            </> 
        );
    }
}

export class MessageContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            hasMessageError: "",
        };
        messageVal = this.state.hasMessageError
        messageText =this.state.message
    }

    handleMessageChange(e) {
        const inputValue = e.target.value;
        let isEmpty;
        
        if(inputValue === "") {
            isEmpty = 1
        }else if(inputValue.length > 1000 ){
            isEmpty = 2
        }else{
            isEmpty = 0
        };
        this.setState({
            message: inputValue,
            hasMessageError: isEmpty,
        },
            () => {                        
                messageVal = this.state.hasMessageError
                messageText =this.state.message
            }
        );
    };
      
    render() {
        let messageErrorText;
        if(this.state.hasMessageError === 1) {
            messageErrorText = (
                <p className="error_text">お問い合わせ内容を入力してください。</p>
            );
        }else if(this.state.hasMessageError === 2){
            messageErrorText = (
                <p className="error_text">お問い合わせ内容は1000文字以内で入力してください。</p>
            );
        };
        return (
            <>
            <div className="message_input">
                <p className="form_label">お問い合わせ内容<span className="required_item">(必須)</span></p>
                <textarea className="message_text" name="message" value={this.state.name} onChange={(e) =>this.handleMessageChange(e)} />
            </div>      
            {messageErrorText}
            </>
        );
    }
}

