import { largeLists,obj } from '../OptionListSystem';
import { errClear } from './ErrClear';
import { totalSum } from './TotalSum';
import { onDetail} from './OnDetail';
import { onDetailBtn } from './OnDetailBtn';

export function onChangeSelectBox(e) {
    errClear();
    const arrayName = e.target.value
        largeLists.forEach(function (value) {
            const largeValue = value.value
            if( arrayName === largeValue){
                const largePrice = value.price;
                const selectLarge = value.name
                const priceArea = document.querySelector('.price_area');
                const selectArea = document.querySelector('.select_area_l');
                const tpriceArea = document.querySelector('.total_price');
                document.querySelectorAll('.price_label_l, .select_label_l, .total_price_label, .detail_message_l').forEach(el => el.remove());
                priceArea.insertAdjacentHTML('beforeend',
                `<label class="price_label_l">${largePrice.toLocaleString()}円~</label>`
                )
                selectArea.insertAdjacentHTML('beforeend',
                `<label class="select_label_l" name=${largeValue}>${selectLarge}</label>`
                )
                tpriceArea.insertAdjacentHTML('beforeend',
                `<label class="total_price_label">${largePrice.toLocaleString()}円~(税別)</label>`
                )
            }
        });
        onDetail(e)

        const area = document.querySelector('.middle_area')
        document.querySelectorAll('.input_box, .middle_item, .middle_name, .middle_price, .select_label_m, .detail_midname, .detail_message_m').forEach(el => el.remove());
        obj[arrayName].forEach(item => {
        area.insertAdjacentHTML('beforeend',
        `<div class="input_box"><input type="checkbox" class="middle_item" value=${item.value} name=${item.name} >
        <label class="middle_name">${item.name}:</label>
        <label class="middle_price">${item.price.toLocaleString()}円~</label></div>`
        )
        })
        const delList = document.querySelectorAll('.middle_item');
        delList.forEach(el => el.addEventListener('change', (event) => {
            const midKey = event.target.value;
            const midName  = event.target.name;
            const selectLabM = document.querySelectorAll('.select_label_m');
            if (event.target.checked) {
                obj[midKey].forEach(function(value){
                    if(midName === value.name ){
                        const midPrice = value.price
                        const selectAreaM = document.querySelector('.select_area_m');
                        selectAreaM.insertAdjacentHTML('beforeend',
                        `<div class="select_label_m" value=${midPrice} name=${midName}><button class="detail_btn" id="js-show-popup" value=${midKey} name=${midName}>説明</button>${midName}
                        <div class="number_area">
                        <label class="middle_sum" value=${midPrice} name=${midPrice}>${midPrice.toLocaleString()}円~</label><div></div>` );
                    }
                })
                totalSum();
                //ボタンイベント付与//
                const detailBtn = document.querySelectorAll('.detail_btn');
                detailBtn.forEach(el => el.addEventListener('click', onDetailBtn));
            } else {
              selectLabM.forEach(function(elem){
                  const readyName = elem.getAttribute('name');
                  if(midName === readyName){
                      elem.remove();
                  }
                })
                totalSum(); 
                document.querySelectorAll('.detail_midname, .detail_message_m').forEach(el =>el.remove());
            }
          })
        );
};