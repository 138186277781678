import React from 'react';
import { Link } from "react-router-dom";

export default class ButtonContact extends React.Component {
    render(){
        return(
                <Link to="/contact" className="btn_area_btn"><button className="contact_b">
                    お問い合わせ
                </button></Link>
        );
    }
}

