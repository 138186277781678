import React from 'react';
import CaseContentWeb from '../components/CaseContentWeb';
import SEO from '../components/seo';


export default class CaseWebLayout extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/caseweb' 
    });
  }
  render() {
    return (
        <main className="layout_container">
          <SEO title='モアプレイン|ホームページ制作' description="コーポレートサイトやランディングページなど様々なWebページを作成いたします。" />
          <CaseContentWeb />
        </main>
    );
  }
}

