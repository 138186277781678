import React from 'react';
import serImg02 from '../../../images/service_02.webp';
import * as FadeIn from './FadeinItem';
import sevImgBody04 from '../../../images/service_body_04.webp';
import sevImgBody05 from '../../../images/service_body_05.webp';
import sevImgBody06 from '../../../images/service_body_06.webp';
import envImg       from '../../../images/environment.webp';
import MicroMacross from './MicroMacross';

export default class InprovContent extends React.Component {
    render(){
        return(
            <article className="main_content">
                    <section className="service_header">
                        <img src={serImg02} alt="#" />
                        <header>
                            <div className="service_name">
                                <h1 className="name_h1">業務改善</h1>
                                <div className="name_icon"></div>
                            </div>
                        </header>
                        <div className="service_name_content">
                            <div className="service_name_content_icon">
                                <p className="service_name_p">事務作業や毎日のルーティン作業など様々な作業をシステム構築にて改善いたします。</p>
                            </div>
                        </div>
                    </section>
                    <section className="service_body">
                        <header>
                            <div className="service_body_title">
                                <h2>改善項目</h2>
                            </div>
                        </header>
                        <FadeIn.Up>
                            <div className="service_body_content-01">
                                <div className="service_body_area-01">
                                    <h3 className="service_body_h3">■業務の効率化</h3>
                                </div>
                                <div className="service_body_area-02">
                                    <p className="service_body_p">業務を効率よく行うには「簡略化」「定形化」「共有化」「可視化」など様々な方向性がありますが、それらを人の目だけで判断していくのは至難の業。
まずは管理システムの導入で大幅な効率化を図り、システムによって可視化された問題点から更なる改善を図ることができるようになります。</p>
                                        <table className="improv_table" border="1">
											<tbody>
												<tr>
													<th>主な特徴</th>
													<td>肥大・分散した各業務データを分析し、最適な一元管理システムを開発します。また、一元管理された売上データや在庫データ、勤怠データなどを元に改善するべき問題点を可視化し、今後の業務改善まで行えるのが強みです。</td>
												</tr>
												<tr>
													<th>主なシステム機能</th>
													<td>
														<div>
															<ul>
																<li>スケジュール進捗管理システム</li>
																<li>売上・顧客管理システム</li>
																<li>発注仕入・在庫管理システム</li>
																<li>勤怠・人事管理システム</li>
															</ul>
														</div>
													</td>
												</tr>
												<tr>
													<th>おすすめの業務</th>
													<td>営業、事務、人事、企画、制作など様々な業務</td>
												</tr>
												<tr>
													<th>導入期間の目安</th>
													<td>
														<dl>
															<dt><span>各システム</span></dt>
															<dd>1ヶ月〜3ヶ月</dd>
														</dl>
													</td>
												</tr>
												<tr>
													<th>導入費用の目安</th>
													<td>
														<dl>
															<dt><span>各システム</span></dt>
															<dd>
																初期費用（開発費用） : 20万円〜300万円/1件
																構築費用 : 1万円〜/1台
																保守点検費用 : 1万円～3万円/月
															</dd>
														</dl>
													</td>
												</tr>
											</tbody>
                                        </table>		
                                </div>
                                <img className="service_body_img" src={sevImgBody04} alt="#"/>
                            </div>
                        </FadeIn.Up>
                        <FadeIn.Up>
                            <div className="service_body_content-01">
                                <div className="service_body_area-01">
                                    <h3 className="service_body_h3">■業務の自動化</h3>
                                </div>
                                <div className="service_body_area-02">
                                <p className="service_body_p">自動クリックさせるだけの単純な自動化ではなく、例外を含めたパターンの洗い出しから統一化を行うことで殆どの業務を自動化することができます。
                                VBA、Visual Basic、Javascript、PHPなど様々な技術を有していますので、RPAだけではできなかった自動化まで行えます。</p>
                                        <table className="improv_table" border="1">
															<tbody>
                                                                <tr>
																<th>主な特徴</th>
																<td>RPAを始め、VBA、Visual Basic、Javascript、PHPなど様々な技術で業務の自動化を行います。PC内や管理システムの操作はもちろん、Webサイトの更新やインターネット上の情報の自動取得など、あらゆる業務の自動化を行います。</td>
															</tr>
															<tr>
																<th>主なシステム機能</th>
																<td>
																	<div>
																		<ul>
																			<li>エクセル作業の自動化</li>
																			<li>CSVデータ変換作業の自動化</li>
																			<li>各種管理システムの入力の自動化</li>
																			<li>Webサイト更新の自動化</li>
																			<li>インターネット上の情報の自動取得など</li>
																		</ul>
																	</div>
																</td>
															</tr>
															<tr>
																<th>おすすめの業務</th>
																<td>受付、予約、営業、事務、人事、企画、制作など様々な業務</td>
															</tr>
															<tr>
																<th>導入期間の目安</th>
																<td>
																	<dl>
																		<dt><span>各システム</span></dt>
																		<dd>1ヶ月〜3ヶ月</dd>
																	</dl>
																</td>
															</tr>
															<tr>
																<th>導入費用の目安</th>
																<td>
																	<dl>
																		<dt><span>各システム</span></dt>
																		<dd>
																			初期費用（開発費用） : 20万円〜300万円/1件
																			構築費用 : 1万円〜/1台
																			保守点検費用 : 1万円～3万円/月
																		</dd>
																	</dl>
																</td>
															</tr>
														</tbody>
                                        </table>
                                </div>
                                <img className="service_body_img" src={sevImgBody05} alt="#"/>
                            </div>
                        </FadeIn.Up>     
                        <FadeIn.Up>
                            <div className="service_body_content-01">
                                <div className="service_body_area-01">
                                    <h3 className="service_body_h3">■業務のテレワーク化</h3>
                                </div>
                                <div className="service_body_area-02">
                                    <p className="service_body_p">チャットやタスク管理、TV会議などの既成アプリケーションの導入からVPN、シンクライアントの導入、独自テレワークシステムの開発まで、ご状況に合わせた最適な環境構築をご提案しています。</p>
                                    <table className="improv_table" border="1">
															<tbody><tr>
																<th>主な特徴</th>
																<td>チャット、共有サーバーなどの既成アプリケーションを使った簡易的な環境構築、VPN、シンクライアントなど自社システムのテレワーク化、独自のテレワークシステムの開発まで、最適なテレワーク環境を構築します。</td>
															</tr>
															<tr>
																<th>主なシステム機能</th>
																<td>
																	<div>
																		<ul>
																			<li>ビジネスチャット</li>
																			<li>共有サーバー</li>
																			<li>タスク管理</li>
																			<li>リモートデスクトップ</li>
																			<li>Web会議</li>
																			<li>VPN</li>
																		</ul>
																	</div>
																</td>
															</tr>
															<tr>
																<th>おすすめの業務</th>
																<td>営業、事務、人事、企画、制作など様々な業務</td>
															</tr>
															<tr>
																<th>導入期間の目安</th>
																<td>
																	<dl>
																		<dt><span>テレワーク環境導入サポート/テレワーク兼用システムの開発</span></dt>
																		<dd>最短3日〜2週間</dd>
																	</dl>
																	<dl>
																		<dd>1ヶ月〜3ヶ月</dd>
																	</dl>
																</td>
															</tr>
															<tr>
																<th>導入費用の目安</th>
																<td>
																	<dl>
																		<dt><span>テレワーク環境導入サポート/テレワーク兼用システムの開発</span></dt>
																		<dd>
																			初期費用 : 10万円〜/1件
																			構築費用 : 2万円〜/1台
																		</dd>
																	</dl>
																	<dl>
																		<dd>
																			開発費用 : 70万円〜/1件
																			構築費用 : 1万円〜/1台
																		</dd>
																	</dl>
																</td>
															</tr>
														</tbody>
                                        </table>
                                </div>
                                <img className="service_body_img" src={sevImgBody06} alt="#"/>
                            </div>
                        </FadeIn.Up>
						<FadeIn.Up>
                            <div className="service_body_content-01">
                                <div className="service_body_area-01">
                                    <h3 className="service_body_h3">■開発環境</h3>
                                </div>
                                <div className="service_body_area-02">
                                    <div className="environment_area">
                                        <ul>
                                            <li className="environment_head">開発言語</li>
                                            <li className="environment_li front">VB/C#.NET</li>
                                            <li className="environment_li">VBA</li>
											<li className="environment_li">PHP/JavaScript</li>
                                        </ul>
                                        <ul>
                                            <li className="environment_head">ツール</li>
                                            <li className="environment_li front">Visual Studio</li>
                                            <li className="environment_li">Excel,Access</li>
											<li className="environment_li">WordPress</li>
                                        </ul>
                                        <ul>
                                            <li className="environment_head">データベース</li>
                                            <li className="environment_li front">SQL server</li>
                                            <li className="environment_li">Accdb</li>
											<li className="environment_li">MySQL</li>
                                        </ul>
                                    </div>
                                </div>
                                <img className="service_body_img" src={envImg} alt="#"/>
                            </div>
                        </FadeIn.Up> 
                    </section>
					<section>
						<FadeIn.Up>
							<MicroMacross />
						</FadeIn.Up>
					</section>
            </article>
        );
    }
}

