import React from "react";
import areaImg from '../../../images/jp-map01.webp'

export default class AreaContent extends React.Component {
  render() {
    return (
      <article className="area-panel">
        <h1 className="area-title">対応エリア</h1>
        <img className="map" src={areaImg} alt="areamap" />

        <h2 className="midashi">全国47都道府県ご対応しています</h2>
        <p className="area-p">
          スカイプやZOOMなどを使用してのヒアリングにて、システム構築の打ち合わせを行い、
        </p>
        <p className="area-p">
          リモートデスクトップを使用しオンラインでの納品が可能ですので、
        </p>
        <p className="area-p">日本全国どこでもご対応可能です。一度ご連絡を！</p>
        <table className="list-of-prefecture">
          <tbody>
            <tr className="list-row01">
              <th className="region">北海道</th>
              <td className="prefecture">北海道</td>
            </tr>
            <tr className="list-row02">
              <th className="region">東北</th>
              <td className="prefecture">
                青森県、岩手県、秋田県、宮城県、山形県、福島県
              </td>
            </tr>
            <tr className="list-row03">
              <th className="region">関東</th>
              <td className="prefecture">
                茨城県、栃木県、群馬県、千葉県、埼玉県、東京都、神奈川県
              </td>
            </tr>
            <tr className="list-row04">
              <th className="region">中部</th>
              <td className="prefecture">
                新潟県、静岡県、山梨県、長野県、愛知県、岐阜県、三重県
              </td>
            </tr>
            <tr className="list-row05">
              <th className="region">北陸</th>
              <td className="prefecture">富山県、石川県、福井県</td>
            </tr>
            <tr className="list-row06">
              <th className="region">近畿</th>
              <td className="prefecture">
                大阪府、京都府、滋賀県、奈良県、和歌山県、兵庫県
              </td>
            </tr>
            <tr className="list-row07">
              <th className="region">中国</th>
              <td className="prefecture">岡山県、広島県、鳥取県、島根県、山口県</td>
            </tr>
            <tr className="list-row08">
              <th className="region">四国</th>
              <td className="prefecture">香川県、徳島県、愛媛県、高知県</td>
            </tr>
            <tr className="list-row09">
              <th className="region">九州・沖縄</th>
              <td className="prefecture">
                福岡県、佐賀県、長崎県、熊本県、大分県、宮崎県、鹿児島県、沖縄県
              </td>
            </tr>
          </tbody>
        </table>
      </article>
    );
  }
}
