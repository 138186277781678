import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppLayout from './imports/ui/layouts/AppLayout';
/* import ReactGA from 'react-ga'; */


export default class App extends React.Component {
  
  render(){
/*     ReactGA.initialize('UA-194358530-1');
    const history = require("history").createBrowserHistory()
    history.listen(({ pathname }) => {
      ReactGA.set({ page: pathname });
      ReactGA.pageview(pathname);
    }); */
/*     const injectGA = () => {
      if (typeof window == 'undefined') {
        return;
      }
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments)};
      gtag('js', new Date());
      gtag('config', 'UA-194358530-1');
      console.log(arguments);
    }; */
  
    return(
      <>
      <Router>
        <AppLayout />
      </Router>
      </>
    );
  };
}
