import React from 'react';
import ImprovContent from '../components/ImprovContent';
import SEO from '../components/seo';


export default class ImprovLayout extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/improv' 
    });
  }
  render() {
    return (
        <main className="layout_container">
          <SEO title='モアプレイン|業務改善' description="テレワーク環境の導入サポートや業務効率化・業務自動化システムの開発を行っています。 各管理システムやWebシステム、ExcelVBAの開発など、幅広い技術で業務をサポートいたします。" />
          <ImprovContent />
        </main>
    );
  }
}

