import React from 'react';
import SEO from '../components/seo';
import SystemContent from '../components/SystemContent';


export default class SystemLayout extends React.Component {
  componentDidMount() {
    window.gtag('config', 'UA-194358530-1', {
      'page_path': '/system' 
    });
  }
  render() {
    return (
        <main className="layout_container">
          <SEO title='モアプレイン|システム開発' description="顧客管理・勤怠管理・不動産管理システム等様々なシステムを要望に合わせてカスタム構築いたします。" />
          <SystemContent />
        </main>
    );
  }
}

