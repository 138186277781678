import React from 'react';
import GnavItem from './GnavItem';


export default class GnavList extends React.Component {
    render() {
      const { gnavs } = this.props;
      return (
        <ul className="gnav-list">
          {gnavs.map(gnav => (
            <GnavItem key={gnav._id} gnav={gnav} />
          ))}
        </ul>
      );
    }
  }