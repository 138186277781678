import React from 'react';
import * as FadeIn from './FadeinItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faHandPointDown } from "@fortawesome/free-regular-svg-icons";
import {checkStyle, handStyle } from "../../Style";
import webImg01 from "../../../images/web_01.webp";
import WebInner from "../../../images/web_inner.webp";
import ButtonSimulatorWeb from "./ButtonSimulatorWeb";
import ButtonContact from "./ButtonContact";


export default class WebProdJsContent extends React.Component {
    render(){
        return(
            <section className="web_service_section_r">
              <div className="section_right-2">
                <header className="web_service_header">
                  <h2 className="web_s_title">JavaScript構築</h2>
                  <p className="web_s_p">スライドショーやプルダウンメニューなど様々な効果をjavaScriptにて実装いたします。</p>
                </header>
                <div className="web_service_body">
                <table  border="1" className="web_s_table">
                  <tbody>
                    <tr>
                        <th>項目</th>
                        <th>内容</th>
                        <th>料金単価目安</th>
                    </tr>
                    <tr>
                        <td rowSpan="8">JavaScript(jQuery)構築</td>
                        <td>モーダルウィンドウ</td>
                        <td>2,500円~</td>
                    </tr>
                    <tr>
                        <td>スムーススクロール</td>
                        <td>3,000円~</td>
                    </tr>
                    <tr>
                        <td>ロールオーバー</td>
                        <td>3,000円~</td>
                    </tr>
                    <tr>
                          <td>画像スライドショー</td>
                          <td>10,000円~</td>
                    </tr>
                    <tr>
                        <td>プルダウンメニュー</td>
                        <td>10,000円~</td>
                    </tr>
                    <tr>
                        <td>ローディング画面</td>
                        <td>10,000円~</td>
                    </tr>
                    <tr>
                        <td>動画設置及び制御</td>
                        <td>15,000円~</td>
                    </tr>
                    <tr>
                        <td>その他</td>
                        <td>別途お見積り</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <footer className="web_service_footer">
                <p>※カスタマイズやゼロからの作成など、ご希望の仕様により価格は変動いたします。※一覧表に記載がないものもご遠慮なくご相談ください！</p>
              </footer>
                <img className="web_img" src={webImg01} alt="#"/>
              </div>
              <FadeIn.DefinL>
              <div className="section_left-2"> 
                      <div className="section_l_inner">
                      <ul className="inner_upper">
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />Webサイトをおしゃれに。</li>
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />使いやすいUIに。</li>
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />単体での構築もご依頼可能</li>
                          <li><FontAwesomeIcon style={checkStyle} icon={faCheck} />柔軟にご要望にお応えします。</li>
                    </ul>
                    <ul className="inner_lower">
                      <li><FontAwesomeIcon style={handStyle} icon={faHandPointDown} />概算料金をシミュレートする</li>
                          <div className="web_b_area">
                            <ButtonSimulatorWeb />
                          </div>
                      <li><FontAwesomeIcon style={handStyle} icon={faHandPointDown} />JavaScript構築についてのお問い合わせ</li>
                          <div className="web_b_area">
                            <ButtonContact />
                          </div>
                    </ul>
                        <img src={WebInner} alt="#" />
                      </div>
                </div>
                </FadeIn.DefinL>
            </section>
        );
    }
}