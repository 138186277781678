import React from 'react';
import simuImg from '../../../images/simurator_title.webp';
import { largeOptions } from '../../OptionListSystem';
import { errClear } from '../../Functions/ErrClear';
import { onCheckedRemoveAll } from '../../Functions/Remove';
import { onCheckedAll } from '../../Functions/OncheckedAll';
import { onChangeSelectBox } from '../../Functions/OnchangeSelectBox';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import *as actions from '../../../actions/index'
import { YesOnly } from '../../Functions/PopUp';

let systemMain, systemPrice, systemText ;


class SimulatorContent extends React.Component {
    
    constructor(props) {
        super(props)
        this.removeAllItem= this.removeAllItem.bind(this);
        this.handleClick= this.handleClick.bind(this);
        this.state = { 
            opPtion: 1 ,
            main: '',
            mid: '',
            price: '',
        };
    }
    
    //すべてクリア//
    removeAllItem(){
        errClear();
        document.querySelectorAll('.input_box, .middle_item, .middle_name, .middle_price, .price_label_l, .select_label_l, .select_label_m,.total_price_label, .detail_message_l').forEach(el => el.remove());
        this.setState({
            opPtion: this.state.opPtion + 1,
          });
    };
    handleClick(e) {
        if(document.querySelector('.price_label_l')) {
            const mainSelect = document.querySelector('.select_label_l').textContent;
            const mainSelectPrice = document.querySelector('.price_label_l').textContent;
            const middleSelect = document.querySelectorAll('.select_label_m');
            const totalPrice = document.querySelector('.total_price_area').textContent;
            systemMain = mainSelect + '【初期費用】' + mainSelectPrice;
            systemPrice = totalPrice;
            systemText = '';
            middleSelect.forEach(function(elem){
                const name = elem.getAttribute('name');
                const price = elem.getAttribute('value');
                const midText = `${name} : ${price}円~ \n`;
                systemText += midText
            });
            this.setState({
                main: systemMain,
                mid: systemText,
                price: systemPrice,
                
            },
                () => {                        
                    this.props.onClick(this.state);
                    this.props.history.push("/contact");
                }
            );
        }else{
            YesOnly();
            document.querySelectorAll('.detail_message,.detail_message_m,.detail_hr').forEach(el => el.remove());
            const detailMsgm = document.querySelector('.inner_message');
            detailMsgm.insertAdjacentHTML('beforeend',
            `<h3 class="detail_message">基幹システムが選択されていません。</h3>`)
            e.preventDefault();
        }
    }
        render() {
            const {opPtion} = this.state;

        return(
        <>
        <article className="simulator_content">
            <header className="simulator_title_area">
                <h1 className="simulator_title">【業務システム開発】</h1>
                <img src={simuImg} alt="#" />
            </header>
            <div className="simulator_body">
                <div className="main_message">基幹システムを選択し、必要に応じて各機能を選択して下さい。</div>
                <div className="choose_system">
                    <select value={this.state.selectValue} key={opPtion} className="large_system" onChange={(e) => onChangeSelectBox(e)}>
                        <option hidden>システムを選択してください</option>
                        {largeOptions}
                    </select>
                    <div className="simuarea_r">
                        <div className="select_system">選択基幹システム</div>
                        <div className="select_area_l">
                            <div className="price_area">【初期費用】</div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="detail_message_area_l">基幹システム詳細</div>
                <hr></hr>
                
                <div className="choose_system_m">
                    <div className="choose_middle">
                        <div>機能一覧</div>
                        <div className="middle_area" ></div>
                        <div className="alert_message"></div>
                        <div className="btn_area">
                            <button className="select_btn" onClick={(e) => onCheckedAll(e)}>全選択</button>
                            <button className="select_btn" onClick={(e) => onCheckedRemoveAll(e)}>全選択解除</button>
                        </div>
                    </div>
                    <div className="simuarea_r">
                        <div className="select_system">選択済み機能</div>
                        <div className="select_area_m"></div>
                    </div>
                </div>
                <hr></hr>
                <div className="total_price_area">
                        <div className="total_text">開発費用合計:</div>
                        <div className="total_price"></div>
                </div>
                <p className="pre_text">※シミュレーターによる開発費はあくまでも目安です。実際のデータ量や組込み機能によって変動します。詳しくはお問い合わせ下さい。</p>
                <hr></hr>
                <p>☆リストに存在しない機能も開発可能です！お問い合わせよりぜひご相談ください。</p>
            </div>
            <footer>
            <div className="simulator_btn_area">
                <button className="simulator_btn_area_btn ok_btn" onClick={this.handleClick}>この内容で問合せ</button>
                <button className="simulator_btn_area_btn reset_btn" onClick={this.removeAllItem}>すべてクリア</button>
            </div>
            </footer>
        </article>
        </>
        )
        }
        
}
            const mapStateToProps = (state) => ({
                mid: state.text.text,
            });
            
            function mapDispatchToProps(dispatch) {
                return {
                onClick(text){
                    dispatch(actions.inputText(text));
                }
                };
            }
export default connect(mapStateToProps, mapDispatchToProps )(withRouter(SimulatorContent));