import React from 'react';
import { Link } from 'react-router-dom';

export default class GnavItem extends React.Component {
    render() {
        const { gnav } = this.props;
        return (
            <li className="gnav-item">
                <Link to={gnav.link}className="gnav-link">{gnav.content}</Link>
            </li>
        );
    }
}