import React from "react";
import maimakuImg from "../../../images/maimaku_logo.webp";

export default class MicroMacross extends React.Component {
  render() {
    return (
      <div className="micromacross">
        <p className="maimaku_p">
          業務改善サービスは、株式会社はにはりとの共同プロジェクト「マイクロマクロス」で提供しております。
        </p>
        <div className="maimaku_content">
          <div className="maimaku_body">
            <header className="maimaku_header">
              <p>業務改善事業</p>
              <h3 className="maimaku_title">
                マイクロマクロス
                <span className="maimaku_span">MicroMacross</span>
              </h3>
              <p>https://micromacross.com</p>
            </header>
            <p>
              マイクロマクロスでは、テレワーク環境の導入サポートや業務効率化・業務自動化システムの開発を行っています。
              各管理システムやWebシステム、ExcelVBAの開発など、幅広い技術で業務をサポートいたします。
            </p>
            <div className="maimaku_link">
              <a href="https://micromacross.com/" target="blank">マイクロマクロスへはこちら</a>
            </div>
          </div>
          <div className="maimaku_logo">
            <div className="maimaku_logo_img">
              <img src={maimakuImg} alt="#" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
