import React from 'react';
import FooterItem from './FooterItem';


export default class FooterList extends React.Component {
    render() {
      const { fItems } = this.props;
      return (
        <ul className="footer-list">
          <li className="footer-item">
            <a href="https://micromacross.com/" target="blank">micromacross</a>
          </li>
          {fItems.map(fItem => (
            <FooterItem key={fItem._id} fItem={fItem} />
          ))}
        </ul>
      );
    }
  }